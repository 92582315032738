export enum DetailsFields {
    status = "Status",
    "sequenceNumber" = "SequenceNumber",
    "unitNumber" = "UnitNumber",
    "modelNumber" = "ModelNumber",
    "modelFull" = "ModelDescription",
    "inspectionTask" = "InspectionTask",
}
export enum MoreDetailsFields {
    "projectNumber" = "Project",
    "disciplineId" = "Discipline",
    "assignedOn" = "AssignedTo",
}

