import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { URL } from "../../Constants/Consts";
import { fetchApi } from "../../Helpers/Functions";
import { addComments } from "../../store/Comments";
import { updateDownloadTriggered, updateHasDownloadedValue } from "../../store/Utilities";
import { RootState } from "../../store/store";

export function storeComments() {
    const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser)
    const dispatch = useDispatch()
    const [isloading, setIsloading] = useState(false)
    const { downloadTriggered: { comments: triggered } } = useSelector(
        (state: RootState) => state.Utilities
    );
    const UserAssignment = SelectedUser.projectNumber && SelectedUser.disciplineId
    const calledOnceRef = useRef(false)
    useEffect(() => {
        (async () => {
            if (navigator.onLine && SelectedUser.jwt && UserAssignment && (triggered)) {
                calledOnceRef.current = true
                setIsloading(true)
                const comments = await fetchApi(URL.getCommentsUrl(SelectedUser.projectNumber)).then(res => res.json())
                dispatch(addComments(comments))
                dispatch(updateHasDownloadedValue({ key: "comments", value: true }))
                dispatch(updateDownloadTriggered({ key: "comments", value: false }))
                setIsloading(false)
                // chain sequences download
                dispatch(updateDownloadTriggered({ key: "sequences", value: true }))
            }
        })()
    }, [SelectedUser.jwt, triggered])

    return { isloading }
}