import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Status, URL } from '../../Constants/Consts';
import userOnlineCheck from '../../Helpers/CustomHooks/userOnlineCheck';
import { fetchApi, splitOnCapitals } from '../../Helpers/Functions';
import { updateChangeLogs } from '../../store/ChangeLogs';
import { updatePunchlistItem } from '../../store/PunchList';
import { setModal, setModalOpen } from '../../store/Utilities';
import { RootState } from '../../store/store';
import { PunchlistApiType } from '../../types/PunchList/PunchListSchema';
import FormDDL from '../CoreComponents/ReactFormComponents/FormDDL';

import { BsPinMap } from "react-icons/bs";
import { FaCamera, FaCheck } from 'react-icons/fa';
import { FaRegCirclePause } from "react-icons/fa6";
import { GoArrowSwitch } from "react-icons/go";
import { LuCopyPlus } from "react-icons/lu";
import { VscIssueReopened } from "react-icons/vsc";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { AiFillCloseSquare } from "react-icons/ai";
import { HiMiniDocumentDuplicate } from "react-icons/hi2";

const PunchListActionButtons = ({ item, addEditPhotoModal }: {
    item: PunchlistApiType,
    addEditPhotoModal
}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const SelectedUser = useSelector(
        (state: RootState) => state.SelectedUser.SelectedUser
    );

    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => setMobileMenuOpen(!isMobileMenuOpen);

    const { status, rfrRequired, identifiedBy, drawingId } = item;
    const isCreator = SelectedUser.email === identifiedBy || SelectedUser.kionEmail === identifiedBy;
    const showMarkComplete = (status === Status.Pending && rfrRequired === 0) || (status === Status.MaterialsReceived && rfrRequired === 0) || status === Status.ReadyForReInspection;
    const showReadyForReInspection = (status === Status.Pending && rfrRequired === 1) || (status === Status.MaterialsReceived && rfrRequired === 1);
    const showReOpenDefect = (status === Status.Duplicate || status === Status.Completed || status === Status.Cancelled || status === Status.ReadyForReInspection);
    const showPlaceOnHold = status !== Status.OnHold && status !== Status.Completed && status !== Status.Cancelled;
    const showReAssignDiscipline = status !== Status.Cancelled && status !== Status.Completed;
    const showAddEditPhoto = status !== Status.Cancelled && status !== Status.Completed;
    const showMarkDuplicate = status !== Status.Duplicate && status !== Status.Completed && status !== Status.Cancelled;
    const showMarkOffHold = status === Status.OnHold;
    const showMarkCancelled = status !== Status.Cancelled && status !== Status.Completed && isCreator;
    const showShowOnMap = !!drawingId;

    const update = React.useCallback(async (e, status) => {
        e.stopPropagation();
        const updatedItem = {
            ...item, status,
            assignedDisciplineId: status === Status.ReadyForReInspection ? Number(item.disciplineId) : Number(item.assignedDisciplineId)
        };
        try {
            await fetchApi(URL.putPunchListsUrl(item.punchListId), {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatedItem),
            })
                .then((res) => res.json())
                .then(() =>
                    dispatch(
                        updatePunchlistItem(updatedItem)
                    )
                );
        } catch (error) {
            if (!navigator.onLine) {
                dispatch(
                    updatePunchlistItem({ ...updatedItem, notSync: true })
                );
            } else throw error;
        } finally {
            dispatch(setModalOpen(false))
        }
    }, [item]);

    const selectedDisciplineRef = useRef(item.assignedDisciplineId)
    const reAssign = async () => {
        if (!selectedDisciplineRef.current) return
        const updatedItem = { ...item, assignedDisciplineId: selectedDisciplineRef.current };
        try {
            await fetchApi(URL.putPunchListsUrl(item.punchListId), {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatedItem),
            })
                .then((res) => res.json())
                .then(() =>
                    dispatch(
                        updatePunchlistItem(updatedItem)
                    )
                );
        } catch (error) {
            if (!navigator.onLine) {
                console.log("offline update!");
                dispatch(
                    updatePunchlistItem({ ...updatedItem, notSync: true })
                );
            } else throw error;
        } finally {
            dispatch(setModalOpen(false))
        }
    }
    const { allDisciplines: disciplines } = useSelector((state: RootState) => state.Discipline)
    const { formState: { errors }, control } = useForm();
    const reAssignModal = () => {
        setMobileMenuOpen(false);
        dispatch(setModal({
            Chilren: () =>
                <FormDDL
                    options={disciplines?.map(d => ({ text: String(d.disciplineName), value: d.id }))}
                    control={control}
                    errors={errors}
                    defaultValue={selectedDisciplineRef.current}
                    inputKey={"AssignedDiscipline"}
                    label={splitOnCapitals("AssignedDiscipline")}
                    name={"AssignedDiscipline"}
                    required={true}
                    onChangeEvent={selectedValue => selectedDisciplineRef.current = Number(selectedValue ?? "")}
                />,
            onConfirm: () => reAssign(),
            open: true,
        }))
    };

    useEffect(() => {
        return () => {
            dispatch(setModalOpen(false))
        }
    }, [])

    const online = userOnlineCheck();
    const fetchChangeLogs = async () => {
        if (online) {
            try {
                const endpoint = URL.getUpdatedChangeLogsUrl(item.projectNumber, item.disciplineId, null, item.punchListId);
                const logs = await fetchApi(endpoint)
                    .then(res => res.json());
                if (logs.status != 400) dispatch(updateChangeLogs({ uuidType: "Punchlist", uuid: item.punchListId, data: logs }));
            } catch (error) {
                console.error('Failed to fetch changelogs:', error);
            }
        }
    };

    const updateModal = (e, message, status) => {
        setMobileMenuOpen(false);
        dispatch(setModal({
            Chilren: () => <>{message}</>,
            onConfirm: async () => {
                try {
                    await update(e, status);
                    if (navigator.onLine) {
                        await fetchChangeLogs();
                    } else {
                        console.log("User is offline, not fetching new changelogs.");
                    }
                } catch (error) {
                    console.error('Error updating or fetching change logs:', error);
                }
            },
            open: true
        }));
    };

    return (
        <>

            {/* Mobile Action Buttons Menu */}

            <div onClick={toggleMobileMenu} className='flex items-center fixed bottom-5 right-5 z-50 bg-orange-1000 p-2 rounded-xl shadow-lg md:hidden'>
                {isMobileMenuOpen ? (
                    <>
                    <span className='text-xs text-white mr-2'>Close:</span>
                    <AiFillCloseSquare className="text-white" />
                    </>
                ) : (
                    <>
                    <span className='text-xs text-white mr-2'>Actions:</span>
                    <LuCopyPlus className="text-white" />
                    </>
                )}
            </div>

            <div className={`${isMobileMenuOpen ? 'flex' : 'hidden'} justify-center fixed inset-0 z-40 bg-white dark:bg-black p-5 md:hidden mt-6`}>
                <div className="w-full max-w-xs mx-auto">
                    {showReOpenDefect && (
                        <div className="flex items-center mt-4" onClick={e => updateModal(e, "Are you sure you want to Re-Open Defect?", Status.Pending)}>
                            <div className="rounded-full bg-[#fdb419] p-2 cursor-pointer"><VscIssueReopened className="text-white" /></div>
                            <div className="text-xs font-bold uppercase ml-4"><p>Re-Open Defect</p></div>
                        </div>
                    )}
                    {showMarkComplete && (
                        <div className="flex items-center mt-4" onClick={e => updateModal(e, "Are you sure you want to mark this Complete?", Status.Completed)}>
                            <div className="rounded-full bg-green-500 p-2 cursor-pointer"><FaCheck className="text-white" /></div>
                            <div className="text-xs font-bold uppercase ml-4"><p>Mark Completed</p></div>
                        </div>
                    )}
                    {showReadyForReInspection && (
                        <div className="flex items-center mt-4" onClick={e => updateModal(e, "Are you sure you want to mark this Ready For Re-Inspection?", Status.ReadyForReInspection)}>
                            <div className="rounded-full bg-green-500 p-2 cursor-pointer"><FaCheck className="text-white" /></div>
                            <div className="text-xs font-bold uppercase ml-4"><p>Ready For Re-Inspection</p></div>
                        </div>
                    )}
                    {showPlaceOnHold && (
                        <div className="flex items-center mt-4" onClick={e => updateModal(e, "Are you sure you want to place this On Hold?", Status.OnHold)}>
                            <div className="rounded-full p-2 flex justify-center items-center cursor-pointer bg-gray-700"><FaRegCirclePause className="text-white" /></div>
                            <div className="text-xs font-bold uppercase ml-4"><p>Place On Hold</p></div>
                        </div>
                    )}
                    {showMarkOffHold && (
                        <div className="flex items-center mt-4" onClick={e => updateModal(e, "Are you sure you want to place this Off-Hold?", Status.MaterialsReceived)}>
                            <div className="rounded-full p-2 flex justify-center items-center cursor-pointer bg-gray-700"><IoIosRemoveCircleOutline className="text-white" /></div>
                            <div className="text-xs font-bold uppercase ml-4"><p>Materials Received</p></div>
                        </div>
                    )}
                    {showReOpenDefect && (
                        <div className="flex items-center mt-4" onClick={e => updateModal(e, "Are you sure you want to Re-Open Defect?", Status.Pending)}>
                            <div className="rounded-full bg-[#fdb419] p-2 flex justify-center items-center cursor-pointer"><VscIssueReopened className="text-white" /></div>
                            <div className="text-xs font-bold uppercase ml-4"><p>Materials Received</p></div>
                        </div>
                    )}
                    {showAddEditPhoto && (
                        <div className="flex items-center mt-4" onClick={() => addEditPhotoModal()}>
                            <div className="rounded-full p-2 flex justify-center items-center cursor-pointer bg-orange-1000"><FaCamera className="text-white" /></div>
                            <div className="text-xs font-bold uppercase ml-4"><p>ADD/EDIT PHOTO</p></div>
                        </div>
                    )}
                    {showReAssignDiscipline && (
                        <div className="flex items-center mt-4" onClick={reAssignModal}>
                            <div className="rounded-full p-2 flex justify-center items-center cursor-pointer bg-orange-1000"><GoArrowSwitch className="text-white" /></div>
                            <div className="text-xs font-bold uppercase ml-4"><p>Re-Assign Discipline</p></div>
                        </div>
                    )}
                    {showMarkDuplicate && (
                        <div className="flex items-center mt-4" onClick={e => updateModal(e, "Are you sure you want to mark this as Duplicate?", Status.Duplicate)}>
                            <div className="rounded-full p-2 flex justify-center items-center cursor-pointer bg-orange-1000"><HiMiniDocumentDuplicate className="text-white" /></div>
                            <div className="text-xs font-bold uppercase ml-4"><p>Mark Duplicate</p></div>
                        </div>
                    )}
                    {showMarkCancelled && (
                        <div className="flex items-center mt-4" onClick={e => updateModal(e, "Are you sure you want to mark this Cancelled?", Status.Cancelled)}>
                            <div className="rounded-full bg-red-500 p-2 flex justify-center items-center cursor-pointer"><MdOutlineCancel className="text-white" /></div>
                            <div className="text-xs font-bold uppercase ml-4"><p>Mark Cancelled</p></div>
                        </div>
                    )}
                    {showShowOnMap && (
                        <div className="flex items-center mt-4" onClick={() => {navigate(`/DrawingLayouts/MapView/${item.drawingId}/${item.punchListId}`)}}>
                            <div className="rounded-full p-2 flex justify-center items-center cursor-pointer bg-orange-1000"><BsPinMap className="text-white" /></div>
                            <div className="text-xs font-bold uppercase ml-4"><p>Show On Map</p></div>
                        </div>
                    )}
                </div>
            </div>

            {/* Top Action Buttons */}

            <div className="justify-start pt-8 mb-8 gap-4 hidden md:inline-flex">
                {showMarkComplete && (
                    <div className="flex flex-col items-center">
                        <div onClick={e => updateModal(e, "Are you sure you want to mark this Complete?", Status.Completed)}
                            className="rounded-full bg-green-500 p-2 flex justify-center items-center cursor-pointer">
                            <FaCheck className="text-white" />
                        </div>
                        <div className="text-center text-xs font-bold mt-1 uppercase">
                            <p>Mark</p>
                            <p>Completed</p>
                        </div>
                    </div>
                )}
                {showReadyForReInspection && (
                    <div className="flex flex-col items-center">
                        <div
                            onClick={e => updateModal(e, "Are you sure you want to mark this Ready For Re-Inspection?", Status.ReadyForReInspection)}
                            className="rounded-full bg-green-500 p-2 flex justify-center items-center cursor-pointer">
                            <FaCheck className="text-white" />
                        </div>
                        <div className="text-center text-xs font-bold mt-1 uppercase">
                            <p>Ready For</p>
                            <p>Re-Inspection</p>
                        </div>
                    </div>
                )}
                {showPlaceOnHold && (
                    <div className="flex flex-col items-center">
                        <div
                            onClick={e => updateModal(e, "Are you sure you want to place this On Hold?", Status.OnHold)}
                            className="rounded-full p-2 flex justify-center items-center cursor-pointer bg-gray-700">
                            <FaRegCirclePause className="text-white" />
                        </div>
                        <div className="text-center text-xs font-bold mt-1 uppercase">
                            <p>Place On</p>
                            <p>Hold</p>
                        </div>
                    </div>
                )}

                {showMarkOffHold && (
                    <div className="flex flex-col items-center">
                        <div
                            onClick={e => updateModal(e, "Are you sure you want to place this as Materials Received?", Status.MaterialsReceived)}
                            className="rounded-full p-2 flex justify-center items-center cursor-pointer bg-gray-700">
                            <IoIosRemoveCircleOutline className="text-white" />
                        </div>
                        <div className="text-center text-xs font-bold mt-1 uppercase">
                            <p>Materials</p>
                            <p>Received</p>
                        </div>
                    </div>
                )}
                {showReOpenDefect && (
                    <div className="flex flex-col items-center">
                        <div
                            onClick={e => updateModal(e, "Are you sure you want to Re-Open Defect?", Status.Pending)}
                            className="rounded-full bg-[#fdb419] p-2 flex justify-center items-center cursor-pointer">
                            <VscIssueReopened className="text-white" />
                        </div>
                        <div className="text-center text-xs font-bold mt-1 uppercase">
                            <p>Re-Open</p>
                            <p>Defect</p>
                        </div>
                    </div>
                )}
                {showAddEditPhoto && (
                    <div className="flex flex-col items-center">
                        <div className="rounded-full p-2 flex justify-center items-center cursor-pointer bg-orange-1000"
                            onClick={() => addEditPhotoModal()}
                        >
                            <FaCamera className="text-white" />
                        </div>
                        <div className="text-center text-xs font-bold mt-1 uppercase">
                            <p>ADD/EDIT</p>
                            <p>PHOTO</p>
                        </div>
                    </div>
                )}
                {showReAssignDiscipline && (
                    <div className="flex flex-col items-center">
                        <div
                            onClick={reAssignModal}
                            className="rounded-full p-2 flex justify-center items-center cursor-pointer bg-orange-1000"
                        >
                            <GoArrowSwitch className="text-white" />
                        </div>
                        <div className="text-center text-xs font-bold mt-1 uppercase">
                            <p>Re-Assign</p>
                            <p>Discipline</p>
                        </div>
                    </div>
                )}
                {showMarkDuplicate && (
                    <div className="flex flex-col items-center">
                        <div
                            onClick={e => updateModal(e, "Are you sure you want to mark this as Duplicate?", Status.Duplicate)}
                            className="rounded-full p-2 flex justify-center items-center cursor-pointer bg-orange-1000"
                        >
                            <HiMiniDocumentDuplicate className="text-white" />
                        </div>
                        <div className="text-center text-xs font-bold mt-1 uppercase">
                            <p>Mark</p>
                            <p>Duplicate</p>
                        </div>
                    </div>
                )}
                {showMarkCancelled && (
                    <div className="flex flex-col items-center">
                        <div
                            onClick={e => updateModal(e, "Are you sure you want to mark this Cancelled?", Status.Cancelled)}
                            className="rounded-full bg-red-500 p-2 flex justify-center items-center cursor-pointer">
                            <MdOutlineCancel className="text-white" />
                        </div>
                        <div className="text-center text-xs font-bold mt-1 uppercase">
                            <p>Mark</p>
                            <p>Cancelled</p>
                        </div>
                    </div>
                )}
                {showShowOnMap && (
                    <div className="flex flex-col items-center">
                        <div
                            onClick={() => {
                                navigate(`/DrawingLayouts/MapView/${item.drawingId}/${item.punchListId}`)
                            }}
                            className="rounded-full p-2 flex justify-center items-center cursor-pointer bg-orange-1000">
                            <BsPinMap className="text-white" />
                        </div>
                        <div className="text-center text-xs font-bold mt-1 uppercase">
                            <p>Show</p>
                            <p>On Map</p>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default PunchListActionButtons;
