import { useMemo, useState } from 'react';
import { MdAddComment } from "react-icons/md";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { twMerge } from "tailwind-merge";
import { RootState } from '../../../store/store';
import { PunchlistApiType } from '../../../types/PunchList/PunchListSchema';
import { UnitChecklistItem } from '../../../types/types';
import SectionHeader from '../Details/SectionHeader';
import ViewTable from '../ViewTable';
import AddCommentComponent from './Comment/AddComments';

export const isChecklist = (obj): obj is UnitChecklistItem => {
    return !('punchListId' in obj);
}

export const isPunchList = (obj): obj is PunchlistApiType => {
    return 'punchListId' in obj;
}

export default function Comments({ item }: { item: UnitChecklistItem | PunchlistApiType }) {
    const { Comments } = useSelector((state: RootState) => state.Comments)
    const navigate = useNavigate()
    const [showAddComment, setshowAddComment] = useState(false)

    const filteredComments = Comments.filter(c => {
        if (isChecklist(item)) {
            return c.unitChecklistUuid === item.checklistUuid
        }
        if (isPunchList(item)) {
            return c.punchlistUuid === item.punchListId
        }
    });

    const sortedComments = useMemo(() =>
        filteredComments.sort((a, b) => new Date(b.addedOn).getTime() - new Date(a.addedOn).getTime()),
        [filteredComments]
    );

    const columns = useMemo(() => [
        {
            Header: "Comment",
            accessor: 'comment1',
        },
        {
            Header: "Added By",
            accessor: 'addedBy',
        },
        {
            Header: "Added On",
            accessor: 'addedOn',
            Cell: ({ value }) => new Date(String(value)).toLocaleString()
        },
    ], [])

    const toggleAddComment = () => setshowAddComment(prevState => !prevState);

    const checklistId = (() => {
        if (isChecklist(item)) {
            return String(item.checklistUuid)
        } else return ""
    })()

    const punchlistId = (() => {
        if (isPunchList(item)) {
            return String(item.punchListId)
        } else return ""
    })()

    return (
        <>
            <div className="flex gap-3 items-center mb-2">
                <div className={twMerge(`flex gap-3 items-center`)}>
                    <SectionHeader title="comments" />
                </div>
                <span className='flex items-center gap-2 text-sm cursor-pointer ml-8' onClick={toggleAddComment}>
                    <MdAddComment className='items-center text-uniteOrange' title={showAddComment ? "Close" : "Add Comment"} />
                    {showAddComment ? "Close" : "Add Comment"}
                </span>
            </div>

            {showAddComment && <div className='m-2'>
                <AddCommentComponent punchlistUuid={punchlistId} unitChecklistUuid={checklistId} />
            </div>}

            {
                sortedComments && sortedComments.length > 0 ? (
                    <div className='font-light'>
                        <ViewTable
                            columns={columns}
                            data={sortedComments}
                            onRowClick={row => navigate(`/Comment/${row.commentUuid}`)}
                        />
                    </div>
                ) : (
                    <span className='text-sm text-gray-500 ml-6'>No Comments</span>
                )
            }
        </>
    )
}