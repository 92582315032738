import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { PunchListViewOptions, Status } from "../../Constants/Consts"
import List from "../../components/CoreComponents/List"
import { RootState } from "../../store/store"
import { PunchlistApiType } from "../../types/PunchList/PunchListSchema.ts";

import { twMerge } from "tailwind-merge";
import SectionHeader from "../../components/CoreComponents/Details/SectionHeader.tsx";
import { TbChecklist } from "react-icons/tb";

export default function PunchList() {
    const {SelectedUser} = useSelector(
        (state: RootState) => state.SelectedUser
    );
    const { allDisciplines: disciplines } = useSelector((state: RootState) => state.Discipline)
    const { ProjectPunchLists } = useSelector((state: RootState) => state.PunchList)
    const navigate = useNavigate()

    interface GroupedPunchList {
        name: string;
        onClickFunction: () => void;
        punchLists: PunchlistApiType[];
    }

    const groupedPunchLists: GroupedPunchList[] = [];

    disciplines.forEach((discipline) => {
        groupedPunchLists.push({
            name: discipline.disciplineName,
            onClickFunction: () => navigate(`/PunchListDiscipline/${discipline.id}`, { state: { disciplinId: discipline.id } }),
            punchLists: ProjectPunchLists.filter(pl => Number(pl.assignedDisciplineId) === Number(discipline.id))
        });
    });
    groupedPunchLists.push({
        name: PunchListViewOptions.ERM_RELEVANT.label,
        onClickFunction: () => navigate(`/PunchList/${PunchListViewOptions.ERM_RELEVANT.name}/view`),
        punchLists: ProjectPunchLists.filter(pl => pl.ermRelevant)
    });
    groupedPunchLists.push({
        name: PunchListViewOptions.MY_PUNCH_LISTS.label,
        onClickFunction: () => navigate(`/PunchList/${PunchListViewOptions.MY_PUNCH_LISTS.name}/view`),
        punchLists: ProjectPunchLists.filter(pl => pl.identifiedBy === SelectedUser.kionEmail)
    });
    groupedPunchLists.push({
        name: PunchListViewOptions.ALL_PUNCH_LISTS.label,
        onClickFunction: () => navigate(`/PunchList/${PunchListViewOptions.ALL_PUNCH_LISTS.name}/view`),
        punchLists: ProjectPunchLists
    });

    return (
        <div className="max-w-4xl">
            <div className={twMerge(`flex gap-3 items-center mt-4 mb-4`)}>
                <SectionHeader title="Punch List Details" />
            </div>

            <List<any>
                data={groupedPunchLists}
                renderChildItem={(item) => () => {
                    return (
                        <div className={`flex items-center`}>
                            <TbChecklist className="w-16 h-16 text-orange-1000" />
                            <div className="ml-2">
                                <h2 className='uppercase font-semibold'>{item.name}</h2>
                                <div>
                                    <span className="text-sm text-gray-600 dark:text-gray-300">{item.punchLists.filter(l => l.status === Status.Pending).length} Pending Punch List</span>
                                </div>
                                <div>
                                    <span className="text-sm text-gray-600 dark:text-gray-300">{item.punchLists.filter(l => l.status === Status.MaterialsReceived).length} Materials Received Punch List</span>
                                </div>
                            </div>
                        </div>
                    )
                }}
                onClick={(item) => item.onClickFunction()}
                isItemDisabled={false}
            />
        </div>

    )
}
