import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { URL } from "../../Constants/Consts"
import { fetchApi } from "../../Helpers/Functions"
import { addMPCStatus, addSequences } from "../../store/SequenceObject"
import { RootState } from "../../store/store"
import { updateDownloadTriggered, updateHasDownloadedValue } from "../../store/Utilities"
import { SequenceObject } from "../../types/Sequence/Sequence"
import { MPCStatus, UnitChecklistItem, UserAssignment } from "../../types/types"

export default function storeSequences() {
    const dispatch = useDispatch()
    const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser)
    const { downloadTriggered: { sequences: triggered } } = useSelector(
        (state: RootState) => state.Utilities
    );
    const [isLoading, setisLoading] = useState(false)
    const controllerRef = useRef<AbortController>()
    const calledOnceRef = useRef(false)
    useEffect(() => {
        (async () => {
            if (navigator.onLine && !!SelectedUser.jwt && (triggered)) {
                dispatch(updateDownloadTriggered({ key: "sequences", value: false }))
                calledOnceRef.current = true
                if (controllerRef.current) {
                    controllerRef.current?.abort?.()
                }
                controllerRef.current = new AbortController()
                setisLoading(true)

                const { UserSequences, mpcStatus } = await fetchUserSequences2(SelectedUser, controllerRef.current.signal)
                dispatch(addMPCStatus(mpcStatus))
                dispatch(addSequences(UserSequences ?? {}))
                dispatch(updateHasDownloadedValue({ key: "sequences", value: true }))
                setisLoading(false)

                // chain changelogs download
                // dispatch(updateDownloadTriggered({ key: "changeLogs", value: true }))

                // chain adHocUnits download
                dispatch(updateDownloadTriggered({ key: "adHocUnits", value: true }))
            }
        })()
    }, [SelectedUser.jwt, SelectedUser.sequence, triggered])
    const isCheckListsLoading = isLoading
    return { isLoading: isCheckListsLoading }
}

async function storeMPCStatus(projectNumber: number, sequences: string): Promise<MPCStatus[]> {
    const sequencesArray = sequences.split(',')
    return await fetchApi(URL.getMPCStatusUrl(projectNumber, sequencesArray)).then(res => res.json()).then(data => {
        return data
    })
}

export const fetchUserSequences2 = async (SelectedUser: UserAssignment, signal?: AbortSignal) => {
    const userAllowedSequences = SelectedUser?.sequence.split(',')
    // get all getChecklistsCACCUrl
    // aggregate them to construct the drop down object
    const userSequencesPromises = await Promise.allSettled(
        userAllowedSequences.map(c =>
            fetchApi(URL.getAllUniteChecklistsUrl(SelectedUser.projectNumber, SelectedUser.disciplineId, c), { signal })
                .then(res => res.json())))
    const userSequences: UnitChecklistItem[] = []
    for (let i = 0; i < userSequencesPromises.length; i++) {
        const element = userSequencesPromises[i];
        if (element.status === 'fulfilled') {
            const f = await element.value
            userSequences.push(f)
        }
    }
    const UserSequences = aggregateSequences(userSequences.flat())
    // MECHANICAL PRE-COMMISSIONING
    let mpcStatus = []
    if (SelectedUser.disciplineId == 5) {
        mpcStatus = await storeMPCStatus(SelectedUser.projectNumber, SelectedUser.sequence)
    }
    return { UserSequences, mpcStatus }
}

const aggregateSequences = (userSequences: UnitChecklistItem[] = [], obj = {}) => {
    for (let i = 0; i < userSequences.length; i++) {
        const sequence = userSequences[i];
        if (!obj[sequence.sequenceNumber]) obj[sequence.sequenceNumber] = {}
        if (!obj[sequence.sequenceNumber][sequence.unitNumber]) obj[sequence.sequenceNumber][sequence.unitNumber] = {}
        if (!obj[sequence.sequenceNumber][sequence.unitNumber][sequence.checklistUuid])
            obj[sequence.sequenceNumber][sequence.unitNumber][sequence.checklistUuid] = sequence
    }
    return obj as SequenceObject
}