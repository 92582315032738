import { useParams } from 'react-router-dom';
import Changelogs from '../../../../../components/CoreComponents/Changelog/Changelogs.tsx';
import DetailsHeader from "../../../../../components/CoreComponents/Details/SectionHeader.tsx";
import CheckListButtons from './CheckListButtons';
import TaskDetails from './ChecklistDetails';
import PunchLists from "./PunchLists.tsx";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store.ts';
import Comments from '../../../../../components/CoreComponents/Comments/Comments.tsx';

export default function Details() {
    const { UnitId, SequenceId, CheckListItemUuid } = useParams()
    const { SequencesObject } = useSelector((state: RootState) => state.SequenceObject)
    const item = SequencesObject[SequenceId][UnitId][CheckListItemUuid];

    return (
        <div>
            <>
                <div className='max-w-4xl'>

                    <div className='mt-2'>
                        <CheckListButtons {...{ UnitId, SequenceId, item }} />
                    </div>

                    <DetailsHeader title="Checklist Details" />

                    <div>
                        <TaskDetails item={item} />
                    </div>

                    <hr className="border-t mt-8 mb-8" />

                    <div className='mt-16'>
                        <Comments item={item} />
                    </div>

                    <div className='mt-16 mb-8'>
                        <PunchLists item={item} />
                    </div>

                    <div className='mt-16 mb-8'>
                        <Changelogs item={item} />
                    </div>

                </div>
            </>
        </div>
    )
}
