// Backend and UX Imports
import React, { useCallback, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { NumberToStatus, Status } from "../../../../Constants/Consts";
import useCheckList from "../../../../Helpers/CustomHooks/useStoredCheckListObject";
import Breadcrumb from "../../../../components/CoreComponents/Breadcrumb";
import List from "../../../../components/CoreComponents/List";
import { UnitChecklistItem } from "../../../../types/types";
import { addNewTask } from "../../../../store/SequenceObject";
import { useDispatch } from 'react-redux';
import { fetchApi } from "../../../../Helpers/Functions";
import { URL } from '../../../../Constants/Consts';

// UI Imports
import { twMerge } from "tailwind-merge";
import { BiReset } from "react-icons/bi";
import { FaRegCheckSquare, FaRegEdit, FaSearch } from "react-icons/fa";
import { FaRegRectangleXmark } from "react-icons/fa6";
import { MdOutlineClear } from "react-icons/md";
import { RiArrowRightSLine } from "react-icons/ri";
import { GrStatusGood } from "react-icons/gr";

import "./CheckListStyles.css";
import { toast } from "react-toastify";

export const IconOrLoader = ({ Icon, Loader, showLoader }): React.ReactNode => {
  return <>{showLoader ? Loader : Icon}</>;
};

export default function CheckList() {
  const { UnitId, SequenceId } = useParams();
  const navigate = useNavigate();
  const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser)
  const { checkListObject, markPassed, navigateToPunchList, markNotApplicable, reset } = useCheckList({ SequenceId, UnitId });
  const BulkActions = {
    MARK_PASS_ALL: { id: "1", name: "Passed" },
    RESET_ALL: { id: "3", name: "Reset Status" },
    MARK_ALL_NOT_APPLICABLE: { id: "4", name: "Not Applicable" }
  }
  const { ProjectPunchLists } = useSelector((state: RootState) => state.PunchList)
  const [currentBulkAction, setCurrentBulkAction] = useState(BulkActions.MARK_PASS_ALL.id);
  const [selectAllEnabled, setSelectAllEnabled] = useState(true);
  const [selectedCheckLists, setSelectedCheckLists] = useState<UnitChecklistItem[]>([]);
  const [bulkEditEnabled, setBulkEditEnabled] = useState(false);
  const [searchInspectionTask, setSearchInspectionTask] = useState('');
  const [filteredCheckList, setFilteredCheckList] = useState(Object.keys(checkListObject).map((k) => checkListObject[k]));

  const { MPCStatus } = useSelector((state: RootState) => state.SequenceObject)
  const [newTask, setNewTask] = useState("");

  useEffect(() => {
    setFilteredCheckList(() =>
      Object.keys(checkListObject)
        .map((k) => checkListObject[k])
        .sort((a, b) => a.columnOrder - b.columnOrder)
    );
  }, [checkListObject]);

  useEffect(() => {
    const filteredItems = getFilteredCheckList();
    setSelectedCheckLists([]);
    const timeOutId = setTimeout(() => {
      setFilteredCheckList(filteredItems);
    }, 400);
    return () => clearTimeout(timeOutId);
  }, [searchInspectionTask]);

  const getFilteredCheckList = useCallback(
    function () {
      return Object.keys(checkListObject)
        .map((k) => checkListObject[k])
        .filter(checkList => checkList.inspectionTask.toLowerCase().includes(searchInspectionTask.toLowerCase()))
        .sort((a, b) => a.columnOrder - b.columnOrder);
    },
    [checkListObject, searchInspectionTask],
  );

  function resetSearchInspectionTask() {
    setSearchInspectionTask("");
  }

  useEffect(() => {
    if (filteredCheckList.every(filteredItem => selectedCheckLists.some(selectedItem => selectedItem.id === filteredItem.id))) {
      setSelectAllEnabled(true);
    } else {
      setSelectAllEnabled(false);
    }
  }, [selectedCheckLists]);

  function addOrRemoveSelectedItem(item) {
    const existingItem = selectedCheckLists.find(selectedItem => selectedItem.id === item.id);
    if (existingItem) {
      setSelectedCheckLists(selectedCheckLists.filter(selectedItem => selectedItem !== existingItem));
    } else {
      setSelectedCheckLists([...selectedCheckLists, item]);
    }
  }

  function addOrRemoveAllSelectedItems() {
    if (selectAllEnabled) {
      setSelectedCheckLists([]);
      return;
    }
    setSelectedCheckLists(filteredCheckList);
  }

  function performBulkOperation() {
    selectedCheckLists.forEach(selectedItem => {
      if (currentBulkAction === BulkActions.MARK_PASS_ALL.id) {
        markPassed(selectedItem)
      } else if (currentBulkAction === BulkActions.MARK_ALL_NOT_APPLICABLE.id) {
        markNotApplicable(selectedItem)
      } else if (currentBulkAction === BulkActions.RESET_ALL.id) {
        reset(selectedItem)
      }
    });
  }

  const [addNewTaskVisible, setNewTaskVisible] = useState(false);
  const toggleButtonsVisibility = () => {
    setNewTaskVisible((prevState) => !prevState);
  };

  const dispatch = useDispatch();
  const addTaskToDatabase = async (newTaskBody) => {
    try {
      const response = await fetchApi(URL.postTasksUrl(), {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newTaskBody)
      });

      const responseData = await response.json();
      return response.ok && responseData.success;
    } catch (error) {
      console.error("An error occurred while adding the task:", error);
      return false;
    }
  };

  const addTaskHandler = async () => {
    if (newTask.trim()) {
      const firstTask = checkListObject[Object.keys(checkListObject)[0]];
      const uuid = crypto.randomUUID().toUpperCase() as `${string}-${string}-${string}-${string}-${string}`
      const newTaskBody: UnitChecklistItem = {
        projectNumber: firstTask.projectNumber,
        disciplineId: firstTask.disciplineId,
        sequenceNumber: firstTask.sequenceNumber,
        unitNumber: firstTask.unitNumber,
        modelNumber: firstTask.modelNumber,
        modelFull: firstTask.modelFull,
        inspectionTask: newTask,
        inspectionId: "ADHOC",
        columnOrder: 1000,
        checklistUuid: uuid,
        status: 1,
        notSync: !navigator.onLine,
        creation: true,
      };

      dispatch(addNewTask({
        SequenceId: newTaskBody.sequenceNumber,
        UnitId: newTaskBody.unitNumber,
        newTask: newTaskBody
      }));

      if (navigator.onLine) {
        await addTaskToDatabase(newTaskBody);
      }

      setNewTask("");
      setNewTaskVisible(false);
    } else {
      alert("Please enter a valid task name.");
    }
  };

  const modelFull = checkListObject && checkListObject[Object.keys(checkListObject)[0]]?.modelFull;
  const modelDetails = checkListObject && checkListObject[Object.keys(checkListObject)[0]]?.modelNumber;
  const breadcrumbTrail = [
    { label: "Sequence:", link: "/Sequences" },
    { label: SequenceId, link: `/Sequence/${SequenceId}/Unit` },
    { label: <RiArrowRightSLine />, icon: true },
    { label: "Unit:", link: `/Sequence/${SequenceId}/Unit` },
    { label: UnitId, link: `/Sequence/${SequenceId}/Unit` },
    { label: <RiArrowRightSLine />, icon: true },
    { label: "Model:" },
    { label: modelDetails + " " + modelFull },
    { label: <RiArrowRightSLine />, icon: true },
    { label: "Checklist" }
  ];

  const handleCheckListClick = (item) => {
    navigate(
      `/Sequence/${SequenceId}/Unit/${UnitId}/CheckList/${item.checklistUuid}`
    );
  };

  return (
    <>
      {(
        <div className="max-w-4xl">
          <Breadcrumb trail={breadcrumbTrail} showIndices={[6, 7, 8, 9]} />
          <div className="flex my-2">
            <div className="border rounded-lg">
              <span className="flex items-center px-2 py-3 text-sm font-medium bg-gray-50 dark:bg-gray-900 dark:text-gray-200">
                <FaSearch />
                <span className="ml-2 hidden md:inline-block">Search</span>
              </span>
            </div>
            <div className="flex border rounded-lg border-1 w-full">
              <input value={searchInspectionTask} onChange={event => setSearchInspectionTask(event.target.value)}
                type="text" placeholder="Search inspection task" className="px-2 w-full" />
              <div className="border border-black rounded-lg bg-gray-50 py-1" hidden={searchInspectionTask === ""}>
                <button type="button" className="py-3" title="Clear" onClick={resetSearchInspectionTask}  >
                  <MdOutlineClear />
                </button>
              </div>
            </div>
            <button type="button" className="ml-2 border rounded-lg bg-orange-1000 text-white dark:bg-orange-1000 dark:text-white" title="Bulk Edit" hidden={bulkEditEnabled}
              onClick={() => setBulkEditEnabled(!bulkEditEnabled)}>
              <span className="flex items-center text-sm font-medium bg-orange-1000 text-white dark:bg-orange-1000 dark:text-white">
                <FaRegEdit />
                <span style={{ whiteSpace: "nowrap" }} className="ml-2">Bulk Edit</span>
              </span>
            </button>
            {bulkEditEnabled && (
              <button
                type="button"
                className="bg-gray-500 text-white text-sm px-4 py-1 ml-2 border rounded-lg"
                title="Exit Bulk Edit"
                onClick={() => {
                  setBulkEditEnabled(false);
                  setSelectedCheckLists([]);
                }}
              >
                <span className="flex items-center text-sm font-medium">
                  <MdOutlineClear />
                  <span style={{ whiteSpace: "nowrap" }} className="ml-2">Exit Edit</span>
                </span>
              </button>
            )}
          </div>
          {bulkEditEnabled && (
            <div className="flex flex-col md:flex-row my-2 justify-between">
              <div className="flex flex-col sm:flex-row">
                <span className="flex items-center text-sm font-medium bg-gray-50 px-2 border rounded-lg dark:bg-gray-900 dark:text-gray-200">
                  <input type="checkbox" hidden={!bulkEditEnabled} onClick={addOrRemoveAllSelectedItems} />
                  <span className="ml-3 mr-2">Select All ({selectedCheckLists.length} selected)</span>
                </span>
                <div className='flex items-center mt-2 md:mt-0'>
                  <div className='items-center'>
                    <span className='items-center text-sm font-medium md:ml-4'>
                      Select Status:
                    </span>
                    <select
                      value={currentBulkAction}
                      className="text-sm font-medium border rounded-lg text-black dark:text-gray-200 bg-gray-50 dark:bg-gray-900 py-0.5 px-0.5 md:py-1 md:px-1 ml-2 mt-0 md:mt-2 md:ml-2"
                      onChange={(e) => setCurrentBulkAction(e.target.value)}
                    >
                      {Object.keys(BulkActions).map(key => (
                        <option key={BulkActions[key].id} value={BulkActions[key].id}>{BulkActions[key].name}</option>
                      ))}
                    </select>
                    <button
                      type="button"
                      className="border rounded-lg bg-orange-1000 text-white px-4 py-1 text-sm ml-4"
                      title="Confirm"
                      onClick={() => {
                        performBulkOperation();
                        setBulkEditEnabled(false);
                        setSelectedCheckLists([]);
                      }}
                    >
                      <span className="flex items-center text-sm font-medium">
                        <GrStatusGood />
                        <span className="ml-2">Save</span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <List<UnitChecklistItem>
            data={filteredCheckList}
            isItemDisabled={false}
            onClick={(item) => handleCheckListClick(item)}
            renderChildItem={(item) => (props) => {
              console.log("itemitemitem", item);
              
              const relatedPendingPLs = ProjectPunchLists.filter(pl => pl.checklistUuid === item.checklistUuid && pl.status == Status.Pending)
              return (
                <div
                  {...props}
                  className={twMerge(
                    `flex flex-col md:flex-row justify-start gap-2 text-left`,
                    "text-left-wrap"
                  )}
                  onClick={null}
                >

                  <div className={`flex items-center gap-2 mr-2`}>
                    <input type="checkbox" hidden={!bulkEditEnabled} defaultChecked={selectedCheckLists.some(selectedItem => selectedItem.id === item.id)} onClick={(e) => {
                      e.stopPropagation();
                      addOrRemoveSelectedItem(item);
                    }} />

                    {/* Passed */}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (relatedPendingPLs.length) {
                          toast("Cannot pass this task until all associated punch list items are completed.", { type: "error" })
                        } else markPassed(item);
                      }}
                      className="p-2 -m-2"
                      style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                    >
                      <FaRegCheckSquare
                        className={`${item.status === Status.Passed ? "text-green-500" : ""}`}
                      />
                    </button>

                    {/* Failed */}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        navigateToPunchList(item);
                      }}
                      className="p-2 -m-2"
                      style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                    >
                      <FaRegRectangleXmark
                        className={`${item.status === Status.Failed ? "text-red-500" : ""}`}
                      />
                    </button>

                    {/* N/A */}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (relatedPendingPLs.length) {
                          toast("Cannot mark this task as N/A until all associated punch list items are completed.", { type: "error" })
                        } else
                          markNotApplicable(item);
                      }}
                      className="p-2 -m-2"
                      style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        display: 'inline-flex',
                        alignItems: 'center',
                        textDecoration: item.status === Status.NA ? "line-through" : "none",
                      }}
                    >
                      N/A
                    </button>

                    {/* Reset */}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (relatedPendingPLs.length) {
                          toast("Cannot reset this task until all associated punch list items are completed.", { type: "error" })
                        } else reset(item);
                      }}
                      className="p-2 -m-2"
                      style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                    >
                      <BiReset />
                    </button>
                  </div>

                  <div>
                    {
                      (SelectedUser.disciplineId == 5) &&
                      (() => {
                        const itemMPCStatus = MPCStatus.find(mpc => mpc.meChecklistUuid === item.checklistUuid)
                        return (<div className="flex items-center space-x-1 uppercase">
                          <div className="font-bold text-xs uppercase">MPC Status:</div>
                          {NumberToStatus[itemMPCStatus?.mpcStatus] && <div className="text-xs">{NumberToStatus[itemMPCStatus?.mpcStatus]}</div>}
                        </div>)
                      })()
                    }
                    <span>
                      <span
                        style={{
                          color:
                            item.status === Status.Passed
                              ? "green"
                              : item.status === Status.Failed
                                ? "red"
                                : "var(--checklist-text)",
                          textDecoration:
                            item.status === Status.NA ? "line-through" : "none",
                        }}
                      >
                        <div className="uppercase text-base font-semibold">{item.inspectionTask}</div>
                      </span>
                    </span>
                  </div>
                </div>
              );
            }}
          />

          {/* ADD NEW CHECKLIST SECTION */}

          <div>
            {addNewTaskVisible && (
              <div className="w-full md:w-auto mb-4">
                <input
                  type="text"
                  placeholder="Add new task description"
                  value={newTask}
                  onChange={(e) => setNewTask(e.target.value)}
                  className="border p-2 rounded-lg w-full"
                />
              </div>
            )}

            {!addNewTaskVisible && (
              <button
                onClick={toggleButtonsVisibility}
                className="bg-orange-1000 text-white p-2 rounded-lg w-full md:w-auto mb-4"
              >
                Add New Task
              </button>
            )}

            <div className="flex flex-col md:flex-row w-full">
              {addNewTaskVisible && (
                <button
                  onClick={toggleButtonsVisibility}
                  className="bg-gray-600 text-white p-2 rounded-lg w-full md:w-auto mb-4 mr-0 md:mr-4 flex-1"
                >
                  Cancel
                </button>
              )}
              {addNewTaskVisible && (
                <button onClick={addTaskHandler} className="bg-orange-1000 text-white p-2 rounded-lg w-full md:w-auto mb-4 flex-1">
                  Add Task
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
