import { useMemo, useState } from 'react';
import { URL as URLS } from '../../Constants/Consts';
import { fetchApi } from '../Functions';

export default function useDownloadPLPhoto() {
    const [isLoading, setIsLoading] = useState(false)
    const filesWorker = useMemo(() => new Worker(new URL('../../components/SyncManager/webWorker.ts', import.meta.url)), [])
    const downLoadPhoto = (photoUrl: string) => {
        if (!photoUrl) return
        const pUrl = photoUrl.replace('punch_list_images/', '');
        setIsLoading(true)
        return fetchApi(URLS.getFilePunchListImage(encodeURIComponent(pUrl)))
            .then(async response => {
                const dd = await response.arrayBuffer()
                console.log("DDDD", dd);
                if (Worker) filesWorker.postMessage({ action: 'storeFile', fileName: `${pUrl.split('/')?.[3]}`, file: dd });
                return {
                    status: "fullFilled",
                    arrayBuffer: dd
                }
            })
            .catch(reason => ({
                status: 'rejected',
                reason
            })).finally(() => setIsLoading(_ => false))
    }
    return { isLoading, downLoadPhoto }
}
