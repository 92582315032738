import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { ERM, Priorities } from '../../../../Constants/Consts';
import { splitOnCapitals } from '../../../../Helpers/Functions';
import { RootState } from '../../../../store/store';
import { detailsSchema, PunchListDetailsType } from '../../../../types/PunchList/PunchListSchema';
import useCheckList from "../../../../Helpers/CustomHooks/useStoredCheckListObject";

import SectionHeader from "../../../../components/CoreComponents/Details/SectionHeader";
import FormDDL from '../../../../components/CoreComponents/ReactFormComponents/FormDDL';
import FormInput from '../../../../components/CoreComponents/ReactFormComponents/FormInput';
import FormYesNo from '../../../../components/CoreComponents/ReactFormComponents/FormYesNo';
import FormMultiSelect from '../../../../components/CoreComponents/ReactFormComponents/FormMultiSelect';
import { addDetails } from '../../../../store/PunchList';

export type ErmOptionsType = {
    label: string;
    value: string;
}

export default function Details({ next, type, id }) {
    const { register, handleSubmit, formState: { errors }, control, reset, setValue } = useForm({
        resolver: zodResolver(detailsSchema),
    });
    const navigate = useNavigate();
    const { allDisciplines: disciplines } = useSelector((state: RootState) => state.Discipline);
    const { Issues } = useSelector((state: RootState) => state.Issues);
    const { Categories } = useSelector((state: RootState) => state.Categories);
    const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser);
    const { selectedSequenceNumber, selectedUnitNumber, selectedInspectionTask } = useSelector((state: RootState) => state.SequenceObject);
    const { checkListObject } = useCheckList({ SequenceId: selectedSequenceNumber, UnitId: selectedUnitNumber });
    const {
        Category, DueDate, AssignedDiscipline, OriginalDiscipline,
        Priority, Issue, DoesPunchListRequireReinspection, DrawingHash,
        ProjectNumber, ErmRelevant, ErmType, IssueDescription, SequenceControlArea, UnitDevice, InspectionTask, ChecklistUuid
    } = useSelector((state: RootState) => state.PunchList.PunchListObject) || {};

    const Details = {
        Category, DueDate, AssignedDiscipline, DrawingHash, ProjectNumber,
        OriginalDiscipline, Priority, Issue, DoesPunchListRequireReinspection, 
        ErmRelevant, ErmType, IssueDescription, SequenceControlArea, UnitDevice, InspectionTask, ChecklistUuid
    };

    const dispatch = useDispatch();

    const onSubmit = data => {
        dispatch(addDetails(data));
        next();
    };

    useEffect(() => {
        const dispatchItems: Partial<PunchListDetailsType> = {
            ProjectNumber: SelectedUser.projectNumber,
            DoesPunchListRequireReinspection,
            ErmRelevant,
            ErmType, 
            IssueDescription,
            SequenceControlArea,
            UnitDevice,
            InspectionTask
        };
        const resetItems: Partial<PunchListDetailsType> = {
            ...Details,
            DueDate: null,
            ProjectNumber: SelectedUser.projectNumber,
            DoesPunchListRequireReinspection: DoesPunchListRequireReinspection === undefined ? true : DoesPunchListRequireReinspection,
            ErmRelevant: ErmRelevant === undefined ? false : ErmRelevant,
            ErmType, 
            IssueDescription,
            SequenceControlArea,
            UnitDevice,
            InspectionTask
        };
        if (type === "CheckList") {
            dispatchItems.ChecklistUuid = id;
            resetItems.ChecklistUuid = id;
            resetItems.SequenceControlArea = String(selectedSequenceNumber);
            resetItems.UnitDevice = String(selectedUnitNumber);
            resetItems.OriginalDiscipline = Number(SelectedUser.disciplineId ?? "");
            resetItems.InspectionTask = selectedInspectionTask || '';
        } else {
            dispatchItems.OriginalDiscipline = SelectedUser.disciplineId;
            resetItems.OriginalDiscipline = SelectedUser.disciplineId;
        }
        dispatch(addDetails(dispatchItems));
        reset(resetItems);
    }, [type, id, selectedSequenceNumber, selectedUnitNumber, selectedInspectionTask, checkListObject]);

    const watchErmRelevant = useWatch({ control, name: "ErmRelevant" });
    useEffect(() => {
        if (watchErmRelevant !== undefined) dispatch(addDetails({ ErmRelevant: watchErmRelevant }));
    }, [watchErmRelevant]);

    const ermOptions: ErmOptionsType[] = Object.keys(ERM).map(p => ({ label: p, value: ERM[p] }));
    const storedErmTypeValues = (ErmType ?? '')?.replaceAll(' ', '')?.split(',').filter(Boolean);
    const storedErmTypeOptions = storedErmTypeValues?.map(str => ermOptions.find(e => e.value == str));
    const [selectedErmType, setSelectedErmType] = useState(storedErmTypeOptions ?? []);
    useEffect(() => {
        const ermTypeValue = selectedErmType.map(option => option?.value).join(',');
        dispatch(addDetails({ ErmType: ermTypeValue }));
        setValue("ErmType", ermTypeValue);
    }, [selectedErmType]);

    const watchPriority = useWatch({ control, name: "Priority" });
    useEffect(() => {
        const dateString = mapPriorityToDate(watchPriority ?? Details.Priority);
        dispatch(addDetails({ DueDate: dateString }));
        setValue("DueDate", dateString);
    }, [watchPriority]);

    const mapPriorityToDate = (priority) => {
        let dateBasedOnPriority = new Date();
        switch (priority) {
            case Priorities.High:
                dateBasedOnPriority.setDate(dateBasedOnPriority.getDate() + 2);
                break;
            case Priorities.Medium:
                dateBasedOnPriority.setDate(dateBasedOnPriority.getDate() + 4);
                break;
            case Priorities.Low:
                dateBasedOnPriority.setDate(dateBasedOnPriority.getDate() + 6);
                break;
            default:
                dateBasedOnPriority = null;
                break;
        }
        return dateBasedOnPriority ? dateBasedOnPriority.toISOString().split('T')[0] : null;
    };

    return (
        <div className='max-w-screen-lg'>
            <div className='mt-8 mb-8'>
                <SectionHeader title='Create Punch List' />
            </div>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormInput
                        label={splitOnCapitals("ProjectNumber")}
                        errors={errors}
                        inputKey={"ProjectNumber"}
                        registeredProps={register("ProjectNumber")}
                        disabled={true}
                        required={true}
                        placeholder={''}
                        defaultValue={ProjectNumber}
                    />
                    <FormDDL
                        options={disciplines?.map(d => ({ text: String(d.disciplineName), value: d.id }))}
                        control={control}
                        errors={errors}
                        defaultValue={SelectedUser.disciplineId}
                        inputKey={"OriginalDiscipline"}
                        label={splitOnCapitals("OriginalDiscipline")}
                        name={"OriginalDiscipline"}
                        required={true}
                        disabled={true}
                    />
                    <FormDDL
                        options={disciplines?.map(d => ({ text: String(d.disciplineName), value: d.id }))}
                        control={control}
                        errors={errors}
                        defaultValue={AssignedDiscipline}
                        inputKey={"AssignedDiscipline"}
                        label={splitOnCapitals("AssignedDiscipline")}
                        name={"AssignedDiscipline"}
                        required={true}
                    />
                    <FormDDL
                        options={[...Categories].sort((a, b) => a.categoryName.localeCompare(b.categoryName)).map(i => ({
                            text: i.categoryName,
                            value: i.categoryId
                        }))}
                        control={control}
                        errors={errors}
                        defaultValue={Category}
                        inputKey={"Category"}
                        label={"Category"}
                        name={"Category"}
                        required={true}
                    />
                    <FormDDL
                        options={[...Issues].sort((a, b) => a.issueText.localeCompare(b.issueText)).map(i => ({
                            text: i.issueText,
                            value: i.issueId
                        }))}
                        control={control}
                        errors={errors}
                        defaultValue={Issue}
                        inputKey={"Issue"}
                        label={"Issue"}
                        name={"Issue"}
                        required={true}
                    />
                    <FormInput
                        errors={errors}
                        inputKey={"DrawingHash"}
                        registeredProps={register("DrawingHash")}
                        label={splitOnCapitals("Drawing #")}
                        placeholder={''}
                        required={true}
                        defaultValue={DrawingHash}
                    />
                    {type === "CheckList" && (
                        <FormInput
                            label={"Inspection Task"}
                            errors={errors}
                            inputKey={"InspectionTask"}
                            registeredProps={register("InspectionTask")}
                            disabled={true}
                            required={true}
                            placeholder={''}
                            defaultValue={selectedInspectionTask || ''}
                            isTextArea={true}
                        />
                    )}
                    <FormInput
                        errors={errors}
                        inputKey={"SequenceControlArea"}
                        registeredProps={register("SequenceControlArea")}
                        label={"Sequence / Control Area"}
                        disabled={type === "CheckList"}
                        placeholder={''}
                        defaultValue={SequenceControlArea}
                    />
                    <FormInput
                        errors={errors}
                        inputKey={"UnitDevice"}
                        registeredProps={register("UnitDevice")}
                        label={"Unit / Device"}
                        disabled={type === "CheckList"}
                        placeholder={''}
                        defaultValue={UnitDevice}
                    />
                    <FormInput
                        errors={errors}
                        inputKey={"IssueDescription"}
                        registeredProps={register("IssueDescription")}
                        label={splitOnCapitals("IssueDescription")}
                        placeholder={''}
                        required={true}
                        defaultValue={IssueDescription}
                        isTextArea={true}
                    />
                    <FormYesNo
                        required={false}
                        defaultValue={ErmRelevant}
                        register={register}
                        errors={errors}
                        control={control}
                        name={"ErmRelevant"}
                        inputKey={"ErmRelevant"}
                        label={"Does This Punch List Add Risk To The Project?"}
                    />
                    <div className='mt-2' hidden={!ErmRelevant}>
                        <FormMultiSelect
                            required={true}
                            options={ermOptions}
                            control={control}
                            errors={errors}
                            defaultValue={selectedErmType}
                            inputKey={"ErmType"}
                            label={"ERM type"}
                            name={"selectedErmType"}
                            onChangeEvent={(selectedOptions: ErmOptionsType[]) => {
                                const values = selectedOptions
                                setSelectedErmType(values);
                            }}
                        />
                    </div>
                    <FormDDL
                        required={true}
                        requiredText={'(Will automatically assign Due Date)'}
                        options={Object.keys(Priorities).map(p => ({ text: p, value: Priorities[p] }))}
                        control={control}
                        errors={errors}
                        defaultValue={Priority}
                        inputKey={"Priority"}
                        label={"Priority"}
                        name={"Priority"}
                    />
                    <FormInput
                        errors={errors}
                        type='Date'
                        inputKey={"DueDate"}
                        registeredProps={register("DueDate", {
                            valueAsDate: false
                        })}
                        label={splitOnCapitals("DueDate")}
                        required={true}
                        requiredText={'(Can update if desired)'}
                        placeholder={''}
                        defaultValue={DueDate}
                    />
                    <FormYesNo
                        required={false}
                        defaultValue={DoesPunchListRequireReinspection}
                        register={register}
                        errors={errors}
                        control={control}
                        name={"DoesPunchListRequireReinspection"}
                        inputKey={"DoesPunchListRequireReinspection"}
                        label="Requires Re-Inspection?"
                    />
                    <div className='flex mt-8 mb-8 space-x-4'>
                        <button className='bg-gray-400 px-4 py-2'
                                onClick={() => navigate(-1)}>
                            Cancel
                        </button>
                        <div className="ml-auto w-full md:w-auto">
                            <button className='save-btn px-4 py-2 w-full md:w-auto'>
                                Next
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
