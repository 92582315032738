export const ssoProperties = {
    redirectUri: import.meta.env.VITE_SSO_REDIRECT_URL,
    clientId: import.meta.env.VITE_SSO_CLIENT_ID,
    authority: "https://login.microsoftonline.com/13c728e0-bb0c-4cf7-8e10-5b327279d6d9",
    knownAuthorities: ["https://login.microsoftonline.com/13c728e0-bb0c-4cf7-8e10-5b327279d6d9"],
    scopes: [import.meta.env.VITE_SSO_SCOPE]
}

export const ssoPropertiesCIAM = {
    redirectUri: import.meta.env.VITE_SSO_REDIRECT_URL,
    clientId: import.meta.env.VITE_CIAM_CLIENT_ID,
    authority: `https://login-uat.dematic.com/${import.meta.env.VITE_CIAM_TENANT_ID}/B2C_1A_CXP_SIGNINSIGNUP_DEMATIC`,
    knownAuthorities: [`https://login-uat.dematic.com/${import.meta.env.VITE_CIAM_TENANT_ID}`],
    scopes: []
}


export const URL = {
    host: import.meta.env.VITE_API_URL,
    getModels: () => `${URL.host}/model`,
    getUnitUrl: (projectNumber, disciplineId, sequenceNumber) => `${URL.host}/unit?projectNumber=${projectNumber}&disciplineId=${disciplineId}&sequenceNumber=${sequenceNumber}`,
    getAdHocUnitsUrl: (projectNumber) => `${URL.host}/unit/adhoc?projectNumber=${projectNumber}`,
    postAdHocUnitUrl: () => `${URL.host}/unit/adhoc`,
    getUnitCheckListUrl: (
        projectNumber,
        disciplineId,
        sequenceNumber,
        unitNumber) => `${URL.host}/unit-checklist?projectNumber=${projectNumber}&disciplineId=${disciplineId}&sequenceNumber=${sequenceNumber}&unitNumber=${unitNumber}`,
    updateUnitCheckListUrl: (unitChecklistUUID) => `${URL.host}/unit-checklist/${unitChecklistUUID}`,
    getAssignmentsUrl: () => `${URL.host}/assignment`,
    getAssignmentUrl: (uuid) => `${URL.host}/assignment/${uuid}`,
    putAssignmentsUrl: (uuid) => `${URL.host}/assignment/${uuid}`,
    postAssignmentsUrl: () => `${URL.host}/assignment`,
    putAssignmentUsingLoginIdUrl: (loginId) => `${URL.host}/assignment?loginOrEmail=${loginId}`,
    getDisciplinesUrl: () => `${URL.host}/discipline`,
    getDisciplinesPerProjectUrl: (projectNumber) => `${URL.host}/discipline?projectNumber=${projectNumber}`,
    getProjectsUrl: () => `${URL.host}/project`,
    getProjectUrl: (projectNumber) => `${URL.host}/project/${projectNumber}`,
    getChecListItemUrl: (uuid) => `${URL.host}/unit-checklist/${uuid}`,
    getPunchListsPerProjectUrl: (projectNumber, disciplineId?, unitChecklistId?) => {
        if (disciplineId && unitChecklistId) return `${URL.host}/punch-list?projectNumber=${projectNumber}&disciplineId=${disciplineId}&unitChecklistId=${unitChecklistId}`
        if (disciplineId) return `${URL.host}/punch-list?projectNumber=${projectNumber}&disciplineId=${disciplineId}`
        else return `${URL.host}/punch-list?projectNumber=${projectNumber}`
    },
    postPunchListsUrl: () => `${URL.host}/punch-list`,
    putPunchListsUrl: (uuid) => `${URL.host}/punch-list/${uuid}`,
    getCommentsUrl: (projectNumber) => `${URL.host}/comment?projectNumber=${projectNumber}`,
    // getCommentsUrl: (projectNumber, disciplineId) => `${URL.host}/comment?projectNumber=${projectNumber}&disciplineId=${disciplineId}`,
    postCommentsUrl: () => `${URL.host}/comment`,
    putCommentsUrl: (commentUuid) => `${URL.host}/comment/${commentUuid}`,
    getChangeLogsUrl: (projectNumber) => `${URL.host}/changelog?projectNumber=${projectNumber}`,
    getChangeLogsPerDisciplineUrl: (projectNumber, disciplineId) => `${URL.host}/changelog?projectNumber=${projectNumber}&disciplineId=${disciplineId}`,
    getUpdatedChangeLogsUrl: (projectNumber, disciplineId, checklistUuid = null, punchListUuid = null) => {
        let url = `${URL.host}/changelog?projectNumber=${projectNumber}&disciplineId=${disciplineId}`;
        if (checklistUuid) {
            url += `&unitChecklistUuid=${checklistUuid}`;
        } else if (punchListUuid) {
            url += `&punchListUuid=${punchListUuid}`;
        }
        return url;
    },
    getChangelogsTypesUrl: () => `${URL.host}/changelogType`,
    getIssuesUrl: () => `${URL.host}/issue`,
    getStatusUrl: () => `${URL.host}/status`,
    getCategoriesUrl: () => `${URL.host}/category`,
    getFilesPathesUrl: (projectNumber: number) => `${URL.host}/file/drawing/list/${projectNumber}`,
    getDrawingsUrl: (projectNumber: number) => `${URL.host}/drawing?projectNumber=${projectNumber}`,
    getFileBlobUrl: (filePath: string) => `${URL.host}/file/drawing/image/${filePath}`,
    getFilePunchListImage: (blobPath) => `${URL.host}/file/punchlistImage/image/${blobPath}`,
    postFilePunchListImage: (projectNumber, imageId, category) => `${URL.host}/file/punchlistImage/upload/${projectNumber}/${imageId}/${category}`,
    getMPCStatusUrl: (projectNumber, sequenceNumbers: Array<string>) => {
        let sequenceNumbersStringParam = ''
        for (let i = 0; i < sequenceNumbers.length; i++) {
            const sequenceNumber = sequenceNumbers[i];
            sequenceNumbersStringParam += `&sequenceNumbers=${sequenceNumber}`
        }
        return `${URL.host}/MpcStatusList?projectNumber=${projectNumber}${sequenceNumbersStringParam}`
    },
    getRelatedChecklistDetailsUrl: (projectNumber: number) => `${URL.host}/RelatedUnitChecklists?projectNumber=${projectNumber}`,
    getCACCUrl: (projectNumber: number, disciplineId: number) => `${URL.host}/cacc?projectNumber=${projectNumber}&disciplineId=${disciplineId}`,
    getChecklistsCACCUrl: (projectNumber: number, disciplineId: number, caCcNumber: string) => `${URL.host}/control-checklist?projectNumber=${projectNumber}&disciplineId=${disciplineId}&caCcNumber=${encodeURIComponent(caCcNumber)}`,
    getAllUniteChecklistsUrl: (projectNumber: number, disciplineId: number, sequenceNumber: string) => `${URL.host}/unit-checklist?projectNumber=${projectNumber}&disciplineId=${disciplineId}&sequenceNumber=${sequenceNumber}`,
    postUserLogUrl: () => `${URL.host}/UserLog`,
    postTasksUrl: () => `${URL.host}/unit-checklist` // Adding a new adhoc task
}

export const imageType = 'webp';
export const lastAccessDate = 'lastAccessDate';


export const StatusArray = [
    {
        "statusId": 1,
        "statusName": "Open",
        "statusDescription": "Checklist has not been inspected."
    },
    {
        "statusId": 2,
        "statusName": "Passed",
        "statusDescription": "Checklist has been inspected and passed."
    },
    {
        "statusId": 3,
        "statusName": "Failed",
        "statusDescription": "Checklist task has a defect/issue."
    },
    {
        "statusId": 4,
        "statusName": "Ready For Re-Inspection",
        "statusDescription": "Defect is ready to be re-inspected."
    },
    {
        "statusId": 5,
        "statusName": "Not Applicable",
        "statusDescription": "Checklist is not needed."
    },
    {
        "statusId": 6,
        "statusName": "Pending",
        "statusDescription": "Punch List is pending after being created (not been worked on)."
    },
    {
        "statusId": 7,
        "statusName": "Duplicate",
        "statusDescription": "Punch List has another identical record."
    },
    {
        "statusId": 8,
        "statusName": "Completed",
        "statusDescription": "Punch List was marked complete (Passed RFR or RFR not needed)."
    },
    {
        "statusId": 9,
        "statusName": "On-Hold",
        "statusDescription": "Punch List is on hold for parts."
    },
    {
        "statusId": 10,
        "statusName": "Cancelled",
        "statusDescription": "Punch List is no longer needed or created by mistake."
    }
]

export const Status = {
    Open: 1,
    Passed: 2,
    Failed: 3,
    ReadyForReInspection: 4,
    NA: 5,
    Pending: 6,
    Duplicate: 7,
    Completed: 8,
    OnHold: 9,
    Cancelled: 10,
    MaterialsReceived: 11
}
export const ActiveStatusNumber = {
    4: 'ReadyForReInspection',
    6: 'Pending',
    9: 'OnHold',
    1: 'MaterialsReceived1'
}

export const PunchListViewOptions = {
    ERM_RELEVANT: {
        name: 'ErmRelevant',
        label: 'ERM Relevant Identified Punch Lists'
    },
    MY_PUNCH_LISTS: {
        name: 'My',
        label: 'PunchList Items You Identified'
    },
    ALL_PUNCH_LISTS: {
        name: 'All',
        label: 'All Punch Lists'
    },
    DISCIPLINE: {
        name: 'Discipline',
        label: ''
    }
}

export const ERM = {
    "Scope Change": "1",
    "Schedule Change": "2",
    "Cost Change": "3"
}

export const NumberToStatus = {
    1: "Open",
    2: "Passed",
    3: "Failed",
    4: "Ready For Re-Inspection",
    5: "N/A",
    6: "Pending",
    7: "Duplicate",
    8: "Completed",
    9: "On-Hold",
    10: "Cancelled",
    11: "Materials Received"
}

export const StatusToColor = {
    0: "#7F443F",
    6: "#fdb419",
    4: "#58B2FC",
    8: "#10E33B",
    10: "#EE2C1A"
}

export const StatusToTwClass = {
    1: "",
    2: "bg-green-500 hover:bg-green-500",
    3: "bg-red-500 hover:bg-red-500",
    5: "line-through",
}

export const Priorities = {
    "High": 1,
    "Medium": 2,
    "Low": 3,
}

export const NumberToPriority = {
    1: "High",
    2: "Medium",
    3: "Low",
}

export const CookiesKeys = {
    jwt: "jwt",
    roles: "roles",
    username: "username",
    name: "name",
    loginPlatform: "loginPlatform",
}

export const LoginPlatforms = {
    azure: "AZURE",
    ciam: "CIAM",
}

export const photosKeys = {
    // order of these items really matters :)
    correctedPic1: "storedCorrectedPic1",
    correctedPic2: "storedCorrectedPic2",
    defectPic1: "storedDefectPic1",
    defectPic2: "storedDefectPic2",
}