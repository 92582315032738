import { useDispatch, useSelector } from "react-redux";
import { CookiesKeys, URL } from "../../Constants/Consts";
import { updateCheckListItem } from "../../store/SequenceObject";
import { RootState } from "../../store/store";
import { ObjectToSync, UnitChecklistItem } from "../../types/types";
import { UUID } from "crypto";
import { SyncItemsTypes } from "./SyncManager";
import Cookies from "js-cookie";

export default function (): { getChecklistItemsToSync: () => ObjectToSync[], updateSyncItem: (itemId: UUID) => void } {
    const { SequencesObject } = useSelector((state: RootState) => state.SequenceObject)
    const { account } = useSelector((state: RootState) => state.SelectedUser)
    const dispatch = useDispatch()
    // traverse into the object to get all the notSync items 
    const getChecklistItemsToSync = () => {
        const checkList = findSyncSequences(SequencesObject)
        const jwt = Cookies.get(CookiesKeys.jwt)
        return checkList.map(d => {
            const method = d.creation ? "POST" : "PATCH"
            const url = d.creation ? URL.postTasksUrl() : URL.updateUnitCheckListUrl(d.checklistUuid)
            const payload = d.creation ? d : { status: d.status }
            return {
                url,
                payload,
                method,
                type: SyncItemsTypes.CheckListItem,
                jwt,
                account
            }
        })
    }
    const updateSyncItem = (itemId) => {
        const checkList = findSyncSequences(SequencesObject)
        const checklistItem = checkList.find(c => c.checklistUuid === itemId)
        if (checklistItem) {
            dispatch(updateCheckListItem({
                SequenceId: String(checklistItem.sequenceNumber),
                UnitId: String(checklistItem.unitNumber),
                checklistItem: { ...checklistItem, notSync: false, creation: false }
            }))
        }
    }
    return { getChecklistItemsToSync, updateSyncItem }
}

export function findSyncSequences(obj) {
    let result = new Array<UnitChecklistItem>;
    for (let prop in obj) {
        if (typeof obj[prop] === 'object' && obj[prop] !== null) {
            result = result.concat(findSyncSequences(obj[prop]));
        } else if (prop === 'notSync' && obj[prop] === true) {
            result.push(obj);
        }
    }
    return result;
}