import { useEffect } from "react";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { routes } from "./Constants/Routes";
import RouterLayout from "./Layouts/RouterLayout";
import Modal from "./components/CoreComponents/Modal";
import LayersProvider from "./components/Providers/LayersProvider";
import MsalLayerProvider from "./components/Providers/MsalLayerProvider";
import ProvidersTreeBuilder from "./components/Providers/ProvidersTreeBuilder";
import QueryProvider from "./components/Providers/QueryProvider";
import ReduxProvider from "./components/Providers/ReduxProvider";
import SWChannelProvider from "./components/Providers/SWChannelProvider";
import NoPage from "./Pages/NoPage";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import { registerSW } from 'virtual:pwa-register'

// Leaving commented out code for loading implementation later

// import { useState, useEffect } from 'react';
// import InitialLoader from './components/InitialLoader/InitialLoader';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={
      <RouterLayout />
    }>
      {Object.keys(routes).map((routeName, i) => {
        const route = routes[routeName];
        return (
          <Route
            key={`${route.Props.path}${i}`}
            {...route.Props}
            element={
              <route.Component />
            }
          />
        );
      })}
      <Route
        path="*"
        Component={() => (
          <NoPage />
        )}
      />
    </Route>
  )
);


function App() {
  const ProvidersTree = ProvidersTreeBuilder([ReduxProvider, QueryProvider, SWChannelProvider, LayersProvider, MsalLayerProvider]);

  //  Need to calculate the loading from the downloaded user data to make this an actual loader
  // Hiding it for now

  useEffect(() => {
    // registerSW({ immediate: true })
    navigator.serviceWorker.getRegistrations().then(registrations => {
      for (let registration of registrations) {
        registration.update();
        if (registration.waiting) {
          console.log("is waitnig from mt");
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }
      }
    });
  }, [])


  return (
    <ProvidersTree>
      <RouterProvider router={router} />
      <Modal />
      <ToastContainer
        pauseOnHover
        theme="light"
      />
    </ProvidersTree>
  );
}

export default App;
