import { RiArrowRightSLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/CoreComponents/Breadcrumb";
import List from "../../../components/CoreComponents/List";
import Loader from "../../../components/CoreComponents/Loader";
import { RootState } from "../../../store/store";
import SearchComponent from "../../../components/CoreComponents/Search.tsx";
import { useState } from "react";

export default function Unit() {
  const { SequenceId } = useParams();
  const navigate = useNavigate();
  const { SequencesObject } = useSelector(
    (state: RootState) => state.SequenceObject
  );

  const unitsIds = Object.keys(SequencesObject[SequenceId]);

  const getUnitStatus = (unitId: string): JSX.Element => {
    const allUnitChecklists = SequencesObject[SequenceId][unitId];
    const checklistStatus = Object.values(allUnitChecklists).map(checklist => checklist.status);
    const allNotStarted = checklistStatus.every(status => status === 1);
    const allCompletedOrNA = checklistStatus.every(status => status === 2 || status === 5);
    const allFailedTotal = checklistStatus.filter(status => status === 3).length;

    if (allNotStarted) return <span className="bg-gray-200 text-gray-800 py-1 px-3 rounded-full">Not Started</span>;
    if (allCompletedOrNA) return <span className="bg-green-200 text-green-800 py-1 px-3 rounded-full">Completed</span>;

    return (
      <div className='inline-flex items-center'>
        <span className="bg-orange-1000 text-white py-1 px-3 rounded-full">In Progress</span>
        <span className="bg-red-200 text-yellow-800 py-1 px-3 rounded-full ml-2">{allFailedTotal} Failed</span>
      </div>
    );
  };

  const breadcrumbTrail = [
    { label: "Sequence:", link: "/Sequences" },
    { label: SequenceId, link: `/Sequences/${SequenceId}` },
    { label: <RiArrowRightSLine />, icon: true },
    { label: "Units" },
  ];
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = (term) => {
    const timeOutId = setTimeout(() => {
      setSearchTerm(term);
    }, 400);
    return () => clearTimeout(timeOutId);
  };

  return (
    <>
      {false ? (
        <Loader />
      ) : (
        <div className="max-w-4xl">
          <Breadcrumb trail={breadcrumbTrail} showIndices={[0, 1, 2, 3]} />
          <div className="flex-grow">
            <SearchComponent onSearch={handleSearch} placeHolder={'Search Units'} />
          </div>
          <List<string>
            data={unitsIds.filter(value => value.toLowerCase().includes(searchTerm))}
            renderChildItem={(item) => (props: {}) =>
              <div {...props} className='ml-2'>
                {item} - {getUnitStatus(item)}
              </div>
            }
            isItemDisabled={false}
            onClick={(unitId) =>
              navigate(`/Sequence/${SequenceId}/Unit/${unitId}/CheckList`)
            }
          />
        </div>
      )}
    </>
  );
}

