import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CookiesKeys, URL } from '../../Constants/Consts'
import { fetchApi } from '../../Helpers/Functions'
import { addStatus } from '../../store/Status'
import { updateDownloadTriggered, updateHasDownloadedValue } from '../../store/Utilities'
import { RootState } from '../../store/store'
import Cookies from "js-cookie";

export default function storeStatus() {
    const [isloading, setIsloading] = React.useState(false)
    const dispatch = useDispatch()
    const SelectedUser = useSelector(
        (state: RootState) => state.SelectedUser.SelectedUser
    );
    const { downloadTriggered: { status: triggered } } = useSelector(
        (state: RootState) => state.Utilities
    );
    const calledOnceRef = useRef(false)
    const currentToken = Cookies.get(CookiesKeys.jwt)
    React.useEffect(() => {
        (async () => {
            if (navigator.onLine && SelectedUser.jwt && currentToken && (!calledOnceRef.current || triggered)) {
                calledOnceRef.current = true
                setIsloading(true)
                const status = await fetchApi(
                    URL.getStatusUrl())
                    .then(res => res.json())
                // update store
                dispatch(addStatus(status))
                dispatch(updateHasDownloadedValue({ key: "status", value: true }))
                dispatch(updateDownloadTriggered({ key: "status", value: false }))
                setIsloading(false)
            }
        })()
    }, [SelectedUser.jwt, triggered])

    return { isloading }
}
