import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../../store/store"
import { useEffect, useState } from "react"
import { fetchApi, splitOnCapitals } from "../../../../../Helpers/Functions"
import { URL } from "../../../../../Constants/Consts"
import { DetailsFields, MoreDetailsFields } from "../../../../../Constants/Enums"
import { UnitChecklistItem } from "../../../../../types/types"
import { updateCheckListItem } from "../../../../../store/SequenceObject"

export default function ({ UnitId, SequenceId, CheckListItemUuid }) {
    const [isLoading, setIsLoading] = useState(false)
    const { SequencesObject } = useSelector((state: RootState) => state.SequenceObject)
    const checkListItem = SequencesObject[SequenceId][UnitId][CheckListItemUuid];
    const dispatch = useDispatch()
    useEffect(() => {
        (async () => {
            try {
                if (navigator.onLine) {
                    setIsLoading(true)
                    const checkListItem = await fetchApi(URL.getChecListItemUrl(CheckListItemUuid)).then(res => res.json()) as UnitChecklistItem
                    // update redux store
                    dispatch(updateCheckListItem({ UnitId, SequenceId, checklistItem: checkListItem }))
                }
            } finally {
                setIsLoading(false)
            }
        })()
    }, [])
    return { item: checkListItem, isLoading }

}

export const getDetilsFieldsArray = (item: UnitChecklistItem, detailsEnum: typeof DetailsFields | typeof MoreDetailsFields) => {
    return Object.keys(item ?? {}).filter(i => detailsEnum[i]).map(k => ({ name: splitOnCapitals(detailsEnum[k]), value: item[k] }))
}