import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { URL } from "../../../Constants/Consts";
import userOnlineCheck from "../../../Helpers/CustomHooks/userOnlineCheck";
import { fetchApi } from "../../../Helpers/Functions";
import { updateChangeLogs } from "../../../store/ChangeLogs";
import { RootState } from "../../../store/store";
import { UnitChecklistItem } from "../../../types/types";
import { isChecklist, isPunchList } from "../Comments/Comments";
import SectionHeader from "../Details/SectionHeader";
import ViewTable from "../ViewTable";
import { PunchlistApiType } from "../../../types/PunchList/PunchListSchema";

export default function Changelogs({ item }: { item: UnitChecklistItem | PunchlistApiType }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const online = userOnlineCheck();
  const run = useRef(false);
  const { ChangeLogs } = useSelector((state: RootState) => state.ChangeLogs);

  useEffect(() => {
    const fetchChangeLogs = async () => {
      if (!run.current && online) {
        let endpoint;
        let uuidType;
        let uuid;

        if (isChecklist(item)) {
          endpoint = URL.getUpdatedChangeLogsUrl(item.projectNumber, item.disciplineId, item.checklistUuid);
          uuidType = "Checklist";
          uuid = item.checklistUuid;
        } else if (isPunchList(item)) {
          endpoint = URL.getUpdatedChangeLogsUrl(item.projectNumber, item.disciplineId, null, item.punchListId);
          uuidType = "Punchlist";
          uuid = item.punchListId;
        }

        if (endpoint) {
          try {
            const logs = await fetchApi(endpoint).then(res => res.json());
            dispatch(updateChangeLogs({ uuidType, uuid, data: logs }));
          } catch (err) {
            console.log("Error updating changelogs:", err);
          }
        }
        run.current = true;
      }
    };

    fetchChangeLogs();
  }, [dispatch, online, item, run, ChangeLogs]);

  const filteredChangelogs = useMemo(
    () =>
      ChangeLogs.filter((c) => {
        if (isChecklist(item)) {
          return c.unitChecklistUuid === item.checklistUuid;
        }
        if (isPunchList(item)) {
          return c.punchlistUuid === item.punchListId;
        }
      }),
    [ChangeLogs, item]
  );
  
  const columns = useMemo(
    () => [
      {
        Header: "Data",
        accessor: "data",
      },
      {
        Header: "Changed By",
        accessor: "changedBy",
      },
      {
        Header: "Changed On",
        accessor: "changedOn",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
    ],
    []
  );

  const sortedChangelogs = useMemo(
    () => filteredChangelogs.sort((a, b) => new Date(b.changedOn).getTime() - new Date(a.changedOn).getTime()),
    [filteredChangelogs]
  );

  return (
    <>
      <div className="flex gap-3 items-center mb-2">
        <div className={twMerge("flex gap-3 items-center")}>
          <SectionHeader title="Changelog" />
        </div>
      </div>
      <div>
        {
          filteredChangelogs && filteredChangelogs.length > 0 ? (
            <ViewTable 
              columns={columns} 
              data={sortedChangelogs} 
              onRowClick={(row) => navigate(`/ChangeLog/${row.changelogUuid}`)} 
            />
          ) : (
            <span className='text-sm text-gray-500 ml-6'>No Changelogs</span>
          )
        }
      </div>
    </>
  );
}
