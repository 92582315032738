import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { URL, imageType } from "../../../Constants/Consts.ts";
import { blobToBase64, fetchApi } from "../../../Helpers/Functions.ts";
import { mapPunchListToAPI } from "../../../Helpers/Mappers.ts";
import Loader from "../../../components/CoreComponents/Loader.tsx";
import { photosKeys } from "../../../components/DownloadManager/storePunchList.tsx";
import {
    addCorrectedPhoto,
    addDefectPhoto,
    updatePunchlistItem
} from "../../../store/PunchList.ts";
import { RootState } from "../../../store/store.ts";
import { PunchlistApiType } from "../../../types/PunchList/PunchListSchema.ts";
import PunchListImages from "./PunchListImages.tsx";


export const PhotoType = {
    DEFECT: 'Defect',
    CORRECTED: 'Corrected'
}
type uploadedPhoto = { photoKey: string, photoPath: string, data: string }

export default function PunchListEditImagesPage() {
    const { punchListId } = useParams();
    const { ProjectPunchLists } = useSelector((state: RootState) => state.PunchList);
    const filteredPunchList = ProjectPunchLists.find((item) => item.punchListId === punchListId);
    const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser);
    const dispatch = useDispatch();

    const imageSelectorRefDefectPhoto1 = useRef(null);
    const imageSelectorRefDefectPhoto2 = useRef(null);
    const imageSelectorRefCorrectedPhoto1 = useRef(null);
    const imageSelectorRefCorrectedPhoto2 = useRef(null);
    const [isUpdating, setIsUpdating] = useState(false);

    const uploadPhoto = async (image: Blob, photoType: string, photoNumber: number, photoKey: string): Promise<uploadedPhoto> => {
        if (!image) return new Promise((resolve) => { resolve({ photoPath: '', data: '', photoKey }) })
        const photoName = `${filteredPunchList.punchListId}.${photoType.toLowerCase()}_pic_${photoNumber}.${imageType}`;
        const formData = new FormData();
        formData.append("files", image, photoName);
        const data = await blobToBase64(image)
        const photoPath = `punch_list_images/${SelectedUser.projectNumber}/${punchListId}/${photoType}/${photoName}`;
        return fetchApi(URL.postFilePunchListImage(SelectedUser.projectNumber, filteredPunchList.punchListId, photoType), {
            method: 'POST',
            body: formData,
            redirect: 'follow'
        }).then(response => {
            if (response.ok) {
                if (photoType === PhotoType.DEFECT) {
                    dispatch(addDefectPhoto({ photoNumber: photoNumber, url: photoPath, value: data }))
                } else if (photoType === PhotoType.CORRECTED) {
                    dispatch(addCorrectedPhoto({ photoNumber: photoNumber, url: photoPath, value: data }))
                }
                return { photoPath, data, photoKey }
            }
        }).catch(_ => {
            if (!navigator.onLine) {
                if (photoType === PhotoType.DEFECT) {
                    dispatch(addDefectPhoto({ photoNumber: photoNumber, value: data, notSync: true, creation: false, url: '' }))
                } else if (photoType === PhotoType.CORRECTED) {
                    dispatch(addCorrectedPhoto({ photoNumber: photoNumber, value: data, notSync: true, creation: true, url: '' }))
                }
            }
            return { photoPath, data, photoKey }
        })

    }

    async function updateBackendData() {
        let picturesResult: uploadedPhoto[]

        picturesResult = await Promise.all<uploadedPhoto>([
            (async () => {
                const defaultData = {
                    photoPath: filteredPunchList.defectPic1,
                    data: filteredPunchList.storedDefectPic1?.value,
                    photoKey: "defectPic1"
                }
                if (!imageSelectorRefDefectPhoto1.current?.getDidChange()) {
                    return Promise.resolve(defaultData)
                }
                return imageSelectorRefDefectPhoto1.current?.getImage().then(blob => {
                    if (blob) {
                        return uploadPhoto(blob, PhotoType.DEFECT, 1, "defectPic1");
                    } else return Promise.resolve({
                        photoPath: "",
                        data: "",
                        photoKey: "defectPic1"
                    })
                })
            })(),
            (async () => {
                const defaultData = {
                    photoPath: filteredPunchList.defectPic2,
                    data: filteredPunchList.storedDefectPic2?.value,
                    photoKey: "defectPic2"
                }
                if (!imageSelectorRefDefectPhoto2.current?.getDidChange()) {
                    return Promise.resolve(defaultData);
                }
                return imageSelectorRefDefectPhoto2.current?.getImage().then(blob => {
                    if (blob) {
                        return uploadPhoto(blob, PhotoType.DEFECT, 2, "defectPic2");
                    } else return Promise.resolve({
                        photoPath: "",
                        data: "",
                        photoKey: "defectPic2"
                    })
                })
            })(),
            (async () => {
                const defaultData = {
                    photoPath: filteredPunchList.correctedPic1,
                    data: filteredPunchList.storedCorrectedPic1?.value,
                    photoKey: "correctedPic1"
                }
                if (!imageSelectorRefCorrectedPhoto1.current?.getDidChange()) {
                    return Promise.resolve(defaultData);
                }
                return imageSelectorRefCorrectedPhoto1.current?.getImage().then(blob => {
                    if (blob) {
                        return uploadPhoto(blob, PhotoType.CORRECTED, 1, "correctedPic1");
                    } else return Promise.resolve({
                        photoPath: "",
                        data: "",
                        photoKey: "correctedPic1"
                    })
                })
            })(),
            (async () => {
                const defaultData = {
                    photoPath: filteredPunchList.correctedPic2,
                    data: filteredPunchList.storedCorrectedPic2?.value,
                    photoKey: "correctedPic2"
                }
                if (!imageSelectorRefCorrectedPhoto2.current?.getDidChange()) {
                    return Promise.resolve(defaultData);
                }
                return imageSelectorRefCorrectedPhoto2.current?.getImage().then(blob => {
                    if (blob) {
                        return uploadPhoto(blob, PhotoType.CORRECTED, 2, "correctedPic2");
                    } else return Promise.resolve({
                        photoPath: "",
                        data: "",
                        photoKey: "correctedPic2"
                    })
                });
            })()
        ]);

        const pictureData: PunchlistApiType = {} as PunchlistApiType
        const storedPictureData = {}
        for (let i = 0; i < picturesResult.length; i++) {
            const pic = picturesResult[i];
            pictureData[pic.photoKey] = pic.photoPath
            storedPictureData[photosKeys[pic.photoKey]] = { value: picturesResult[i].data }
            if (pic?.data) {
            }
        }

        const data = {
            ...filteredPunchList,
            ...pictureData
        } as PunchlistApiType
        const apiData = mapPunchListToAPI(data);
        await fetchApi(URL.putPunchListsUrl(punchListId), {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(apiData)
        }).then(res => res.json()).then((_) => {
            const dataWithStoredPhotos: PunchlistApiType = {
                ...apiData,
                ...storedPictureData
            }
            dispatch(updatePunchlistItem({ ...dataWithStoredPhotos }))
        }).catch(_ => {
            if (!navigator.onLine) {
                for (let i = 0; i < Object.keys(storedPictureData).length; i++) {
                    const storedPhotoKey = Object.keys(storedPictureData)[i];
                    if (storedPictureData[storedPhotoKey]?.value) {
                        storedPictureData[storedPhotoKey].notSync = true
                        storedPictureData[storedPhotoKey].creation = true
                    }
                }
                const dataWithStoredPhotos: PunchlistApiType = {
                    ...apiData,
                    ...storedPictureData
                }
                dispatch(updatePunchlistItem({ ...dataWithStoredPhotos, notSync: true, creation: filteredPunchList.creation }))
            }
        })
    }

    const navigate = useNavigate();
    const handleSave = async () => {
        setIsUpdating(true);
        await updateBackendData();
        setIsUpdating(false);
        navigate(-1);
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <>
            <div style={isUpdating ? { display: '' } : { display: 'none' }}>
                <Loader />
            </div>

            <div className="max-w-screen-lg relative" style={!isUpdating ? { display: '' } : { display: 'none' }}>
                <PunchListImages item={filteredPunchList} imageEditEnabled={true}
                    imageSelectorRefDefectPhoto1={imageSelectorRefDefectPhoto1}
                    imageSelectorRefDefectPhoto2={imageSelectorRefDefectPhoto2}
                    imageSelectorRefCorrectedPhoto1={imageSelectorRefCorrectedPhoto1}
                    imageSelectorRefCorrectedPhoto2={imageSelectorRefCorrectedPhoto2}
                    addEditPhotoModal={() => {
                    }} 
                />
                
                <div className={'mt-3'}>
                    <button className='bg-gray-400 absolute left-0' onClick={() => navigate(-1)}>
                        Cancel
                    </button>
                    <button type='button' onClick={handleSave} className='save-btn absolute right-0'>
                        Save
                    </button>
                </div>
            </div>
        </>
    )
}
