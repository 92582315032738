import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { URL } from "../../Constants/Consts";
import { fetchApi } from "../../Helpers/Functions";
import { addChangeLogs } from "../../store/ChangeLogs";
import { updateDownloadTriggered, updateHasDownloadedValue } from "../../store/Utilities";
import { RootState } from "../../store/store";

export function storeChangeLogs() {
    const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser)
    const dispatch = useDispatch()
    const [isloading, setIsloading] = useState(false)
    const { downloadTriggered: { changeLogs: triggered } } = useSelector(
        (state: RootState) => state.Utilities
    );
    const { allDisciplines } = useSelector((state: RootState) => state.Discipline);
    const UserAssignment = SelectedUser.projectNumber
    const calledOnceRef = useRef(false)
    useEffect(() => {
        (async () => {
            if (navigator.onLine && SelectedUser.jwt && UserAssignment && (triggered)) {
                calledOnceRef.current = true
                setIsloading(true)
                const logs = await Promise.all(allDisciplines.map(d => fetchApi(URL.getChangeLogsPerDisciplineUrl(SelectedUser.projectNumber, d.id))
                    .then(res => res.json())))
                if (logs[0]?.status != 400) dispatch(addChangeLogs(logs.flat()))
                dispatch(updateHasDownloadedValue({ key: "changeLogs", value: true }))
                dispatch(updateDownloadTriggered({ key: "changeLogs", value: false }))
                setIsloading(false)
                // chain adHocUnits download
                dispatch(updateDownloadTriggered({ key: "adHocUnits", value: true }))
            }
        })()
    }, [SelectedUser.jwt, UserAssignment, triggered])
    return { isloading }
}