import { Map as OpenlayersMap, Overlay, View } from 'ol'
import { getCenter } from 'ol/extent'
import ImageLayer from 'ol/layer/Image'
import VectorLayer from 'ol/layer/Vector'
import Cluster from 'ol/source/Cluster'
import Static from 'ol/source/ImageStatic'
import VectorSource from 'ol/source/Vector'
import { createContext, useEffect, useState } from 'react'

type olContext = {
    mapObject: OpenlayersMap,
    pointersLayer: VectorLayer<Cluster>, //<VectorSource<Feature<Point>>>
    staticImageLayer: ImageLayer<Static>,
    overlayObject: Overlay
}
const lowerLeft = [31.245760348745584, 30.04123277962705]
const upperRight = [31.249244715229402, 30.04410585509858]
export const fextent = [...lowerLeft, ...upperRight]
export const layerContext = createContext<olContext | null>(null)
export default function LayersProvider({ children }: { children: React.ReactNode }) {
    const [mapObject, setmapObject] = useState(null)
    const [pointersLayer, setPointersLayer] = useState(null)
    const [staticImageLayer, setStaticImageLayer] = useState(null)
    const [overlayObject, setOverlayObject] = useState(null)
    useEffect(() => {
        const pointerVectorSource = new VectorSource({
            // projection: "EPSG:4326",
            features: [],
        })
        const clusterSource = new Cluster({
            distance: 10,
            // minDistance: 10,
            source: pointerVectorSource,
        });
        const pointLayer = new VectorLayer({
            source: clusterSource,
            // style: pointerStyle,
            // extent: fextent
        });
        setPointersLayer(pointLayer)
        pointLayer.set("title", 'pointLayer')
        const layoutLayer = new ImageLayer({
            // extent: fextent,
            zIndex: 0
        })
        layoutLayer.set("title", "layout")
        setStaticImageLayer(layoutLayer)
        const map = new OpenlayersMap({
            target: null,
            layers: [layoutLayer, pointLayer],
            view: new View({
                projection: "EPSG:4326",// 'EPSG:3857',
                center: getCenter(fextent),
                zoom: 17.5,
                maxZoom: 20,
                minZoom: 17
            }),
        })
        setmapObject(map)
        const overlay = new Overlay({
            autoPan: {
                animation: {
                    duration: 250,
                },
            },
        });
        map.addOverlay(overlay)
        setOverlayObject(overlay)
    }, [])

    return (
        <layerContext.Provider value={{ mapObject, pointersLayer, staticImageLayer, overlayObject }}>
            {children}
        </layerContext.Provider>
    )
}
