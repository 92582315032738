import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { URL } from "../../Constants/Consts";
import { fetchApi } from "../../Helpers/Functions";
import { addUsers } from "../../store/User";
import { updateDownloadTriggered, updateHasDownloadedValue } from "../../store/Utilities";
import { RootState } from "../../store/store";
import { AssignmentApiData, UserAssignment } from "../../types/types";
import { ROLES } from "../../utils/roles";

export default function storeAllUsers() {
    const disciplines = useSelector((state: RootState) => state.Discipline.allDisciplines)
    const projects = useSelector((state: RootState) => state.Project.projects)
    const dispatch = useDispatch()
    const SelectedUser = useSelector(
        (state: RootState) => state.SelectedUser.SelectedUser
    );
    
    const admin = SelectedUser.role === ROLES.UNITE_AdminUser || SelectedUser.role === ROLES.UNITE_SuperAdmin || ROLES.UNITE_IT
    const { downloadTriggered: { allUsers: triggered } } = useSelector(
        (state: RootState) => state.Utilities
    );
    
    const [isLoading, setIsLoading] = useState(false)
    const calledOnceRef = useRef(false)

    useEffect(() => {
        (async () => {
            if (!admin && triggered) {
                // only chain downloading comments
                dispatch(updateDownloadTriggered({ key: "comments", value: true }))
                dispatch(updateDownloadTriggered({ key: "allUsers", value: false }))
                return
            }
            if (SelectedUser.jwt && triggered && projects.length && disciplines.length) {
                calledOnceRef.current = true
                setIsLoading(true)
                const usersAssignments = await fetchApi(URL.getAssignmentsUrl()).then(res => res.json()) as Array<AssignmentApiData>
                const tableData = usersAssignments.map(ua => {
                    return {
                        ...ua,
                        project: projects.find(p => p.projectNumber === ua.projectNumber)?.projectName ?? '',
                        discipline: disciplines?.find(d => d.id === ua.disciplineId)?.disciplineName ?? '',
                    }
                }) as Array<UserAssignment>
                dispatch(addUsers(tableData))
                dispatch(updateHasDownloadedValue({ key: "allUsers", value: true }))
                dispatch(updateDownloadTriggered({ key: "allUsers", value: false }))
                setIsLoading(false)
                // chain downloading comments
                dispatch(updateDownloadTriggered({ key: "comments", value: true }))
            }
        })()

    }, [SelectedUser.jwt, triggered, disciplines, projects])

    return { isLoading }
}

