import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { URL } from '../../Constants/Consts'
import { blobToBase64, fetchApi } from '../../Helpers/Functions'
import { addUrls } from '../../store/Drawings'
import { updateDownloadTriggered, updateHasDownloadedValue } from '../../store/Utilities'
import { RootState } from '../../store/store'
import { DrawingType } from '../../types/types'

export default function storeDrawings() {
    const [isloading, setIsloading] = React.useState(false)
    const dispatch = useDispatch()
    const { SelectedUser } = useSelector(
        (state: RootState) => state.SelectedUser
    );
    const { downloadTriggered: { drawings: triggered } } = useSelector(
        (state: RootState) => state.Utilities
    );
    const calledOnceRef = useRef(false)
    React.useEffect(() => {
        if (navigator.onLine && SelectedUser.jwt && SelectedUser.projectNumber && (triggered)) {
            (async () => {
                calledOnceRef.current = true
                setIsloading(true)
                try {
                    const drawings = await fetchApi(
                        URL.getDrawingsUrl(SelectedUser.projectNumber))
                        .then(res => res.json()) as DrawingType[]
                    const objectUrls = await Promise.all(await drawings.map(async ({ drawingId, drawingName, id }) =>
                        (await fetchApi(URL.getFileBlobUrl(encodeURIComponent(drawingId.substring(11))))
                        ).blob()
                            .then(blobToBase64).then(async data => {
                                const blobObject = await fetch(data)
                                    .then(response => response.blob())
                                return { data: data, url: window.URL.createObjectURL(blobObject), label: drawingName, id }
                            }
                            )))
                    // update store
                    dispatch(addUrls(objectUrls))
                    dispatch(updateHasDownloadedValue({ key: "drawings", value: true }))
                    dispatch(updateDownloadTriggered({ key: "drawings", value: false }))
                } catch (error) {
                    if (error.name === 'AbortError') console.error("error from drawings downloading", error);
                } finally {
                    setIsloading(false)
                    // chain download all users 
                    dispatch(updateDownloadTriggered({ key: "allUsers", value: true }))
                }
            })()
        }
    }, [SelectedUser.jwt, SelectedUser.projectNumber, triggered])

    return { isloading }
}

// const fetchDrawings = async (SelectedUser: UserAssignment, controllerRef: React.MutableRefObject<AbortController>) => {
//     if (controllerRef.current) {
//         controllerRef.current.abort()
//     }
//     controllerRef.current = new AbortController()
//     const drawings = await fetchApi(
//         URL.getDrawingsUrl(SelectedUser.projectNumber), { signal: controllerRef.current.signal })
//         .then(res => res.json()) as DrawingType[]
//     const objectUrls = await Promise.all(await drawings.map(async ({ drawingId, drawingName, id }) =>
//         (await fetchApi(URL.getFileBlobUrl(encodeURIComponent(drawingId.substring(11))),
//             { signal: controllerRef.current.signal })
//         ).blob()
//             .then(blobToBase64).then(async data => {
//                 const blobObject = await fetch(data)
//                     .then(response => response.blob())
//                 return { data: data, url: window.URL.createObjectURL(blobObject), label: drawingName, id }
//             }
//             )))
//     return objectUrls
// }