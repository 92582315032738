import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { useTable } from 'react-table';
import { ERM, NumberToPriority, Status, URL } from "../../../Constants/Consts";
import { fetchApi } from "../../../Helpers/Functions";
import { updatePunchlistItem } from "../../../store/PunchList";
import { RootState } from "../../../store/store";
import { PunchlistApiType } from "../../../types/PunchList/PunchListSchema";

import { twMerge } from "tailwind-merge";
import EditSaveBtn from "../../../components/CoreComponents/Details/EditSaveBtn";
import SectionHeader from "../../../components/CoreComponents/Details/SectionHeader";
import StatusTextIcon from "../../../components/CoreComponents/StatusTextIcons";
import { updateChangeLogs } from "../../../store/ChangeLogs";

const selectAllDisciplines = (state: RootState) => state.Discipline.allDisciplines;

const shownDetails = {
    status: "Status",
    adhocSequenceCa: "Sequence / Control",
    adhocUnitDevice: "Unit Number",
    materialId: "Category",
    issueId: "Issue",
    issueDesc: "Issue Description",
    disciplineId: "Original Discipline",
    assignedDisciplineId: "Assigned Discipline",
    drawingNumber: "Drawing #",
    punchListId: "Punch List Tag",
    priority: "Priority",
    dateIdentified: "Date Identified",
    dueDate: "Due Date",
    rfrRequired: "Requires Re-Inspection",
    ermRelevant: "ERM Relevant",
    ermType: "ERM Type"
}

export default function PunchListDetails({ item, detailsEditEnabled, setDetailsEditEnabled }: {
    item: PunchlistApiType,
    detailsEditEnabled: boolean,
    setDetailsEditEnabled
}) {

    const { Categories } = useSelector((state: RootState) => state.Categories);
    const { Issues } = useSelector((state: RootState) => state.Issues);
    const { kionEmail: userEmail } = useSelector((state: RootState) => state.SelectedUser.SelectedUser);
    const isCreator = userEmail === item.identifiedBy;
    const isStatusNotComplete = item.status !== Status.Completed;
    const columns = useMemo(() => [
        {
            accessor: 'name',
        },
        {
            accessor: 'value',
        },
    ], []);

    const disciplines = useSelector(selectAllDisciplines);
    const getDisciplineName = useCallback((disciplineId) => {
        const discipline = disciplines.find(d => d.id === Number(disciplineId));
        return discipline ? discipline.disciplineName : 'N/A';
    }, [disciplines]);

    const detailsData = Object.keys(item).reduce((acc, key) => {
        if (shownDetails[key]) {
            if (key === "status" || key === "adhocSequenceCa" || key === "adhocUnitDevice" ||
                key === "priority" || key === "dueDate" || key === "rfrRequired") {
                acc.push({ name: shownDetails[key], value: item[key] });
            } else if (key === "materialId") {
                acc.push({ name: shownDetails[key], value: Categories.find(c => c.categoryId == item[key])?.categoryId });
            } else if (key === "issueId") {
                acc.push({ name: shownDetails[key], value: Issues.find(issue => issue.issueId == item[key])?.issueId });
            } else if (key === "punchListId") {
                acc.push({ name: shownDetails[key], value: item[key].substring(0, 8) });
            } else {
                acc.push({ name: shownDetails[key], value: item[key] });
            }
        }
        return acc;
    }, []);

    const [data, setData] = useState(detailsData)
    useEffect(() => {
        setData(detailsData)
    }, [item])

    const [disable, setDisable] = useState(!detailsEditEnabled)
    const updateMyData = (rowIndex, columnId, value) => {
        setData(old =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            })
        );
    };

    const dispatch = useDispatch()
    const oldDisableState = useRef<boolean>(true)

    useEffect(() => {
        setDetailsEditEnabled(disable)
        if (disable && !oldDisableState.current) {
            updateBackendData().then((updated) => {
                if (updated) fetchChangeLogs(item, (logs) => {
                    dispatch(updateChangeLogs({ uuidType: "Punchlist", uuid: item.punchListId, data: logs }));
                })
            })
        }
        oldDisableState.current = disable
    }, [disable]);

    const updateBackendData = () => {
        const updatedData = data.reduce((acc, dataRow) => {
            const shownItemKey = Object.keys(shownDetails).find(o => shownDetails[o] == dataRow.name)
            if (shownDetails[shownItemKey] !== shownDetails.punchListId) acc[shownItemKey] = dataRow.value
            return acc
        }, {})
        const punchlistData = {
            ...item,
            ...updatedData,
        } as PunchlistApiType
        const apiData = punchlistData
        return fetchApi(URL.putPunchListsUrl(item.punchListId), {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(apiData)
        }).then(res => res.json()).then((_) => {
            dispatch(updatePunchlistItem({ ...apiData }))
            return Promise.resolve(true)
        }).catch(_ => {
            if (!navigator.onLine) {
                dispatch(updatePunchlistItem({ ...apiData, notSync: true }))
            }
            return Promise.resolve(false)
        })
    }

    const defaultColumn = {
        Cell: EditableCell,
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
        defaultColumn,
        updateMyData,
        disable,
        isCreator,
        autoResetPage: false
    });

    const sortedRows = Object.keys(shownDetails).map(k => rows.find(r => r.original.name == shownDetails[k]));
    const isErmRelevantYes = () => {
        const ermRelevantRow = data.find(row => row.name === shownDetails.ermRelevant);
        return ermRelevantRow && ermRelevantRow.value === 1;
    }

    return (
        <div>
            <div className="flex gap-3 items-center mb-6 mt-4 md:mt-2">
                <div className={twMerge(`flex gap-3 items-center`)}>
                    <SectionHeader title="Punch List Details" />
                </div>
                {isStatusNotComplete &&
                    <EditSaveBtn
                        disable={disable}
                        onClickHandler={setDisable}
                        buttonText={'Edit Details'}
                    />
                }
            </div>

            <table {...getTableProps()} className="text-base text-left table-auto w-[85%]">
                <thead className="font-medium">
                    {headerGroups.map((headerGroup) => {
                        const { key, ...restProps } = headerGroup.getHeaderGroupProps();
                        return (
                            <tr key={key} {...restProps}>
                                {headerGroup.headers.map((column) => {
                                    const headerCellValue = column.Header()?.props?.children
                                    if (headerCellValue?.trim?.() === "") return null
                                    const { key, ...restProps } = column.getHeaderProps();
                                    return (
                                        <th className="py-2" key={key} {...restProps}>
                                            {column.render("Header")}
                                        </th>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {sortedRows.map((row) => {
                        prepareRow(row);
                        const { key, ...restProps } = row.getRowProps();
                        if (row.original.name === shownDetails.ermType && !isErmRelevantYes()) {
                            return null;
                        }
                        if (row.original.name === shownDetails.ermType) {
                            const rfr = data.find(r => r.name === shownDetails.rfrRequired);
                            if (rfr && rfr.value?.toString().toLowerCase() === "no") return null;
                        }
                        return (
                            <tr key={key} style={{ width: "85vw" }} className="cursor-pointer" {...restProps}>
                                {row.cells.map((cell) => {
                                    const cellProps = cell.getCellProps();
                                    return (
                                        <td className="py-2 px-0 md:py-0.5 md:px-4" {...cellProps}>
                                            <div className="flex">{cell.render("Cell", { getDisciplineName })}</div>
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    )
}

const EditableCell = ({
    value: initialValue,
    row,
    column,
    updateMyData,
    disable,
    isCreator
}) => {

    const { id } = column
    const { index } = row
    const [value, setValue] = useState(initialValue);

    const disciplines = useSelector(selectAllDisciplines);
    const getDisciplineName = useCallback((disciplineId) => {
        const discipline = disciplines.find(d => d.id === Number(disciplineId));
        return discipline ? discipline.disciplineName : 'N/A';
    }, [disciplines]);

    const inputStyle = disable
        ? {
            border: 'none',
            background: 'transparent',
            color: 'inherit',
            cursor: 'default',
            width: '100%',
            minWidth: '200px',
            whiteSpace: 'wrap'
        }
        : {
            border: '1px solid #fdb419',
            background: 'inherit',
            cursor: 'text',
            width: '100%',
            minWidth: '200px',
            whiteSpace: 'wrap'
        };

    const onChange = e => {
        setValue(e.target.value);
    };
    const onBlur = () => {
        updateMyData(index, id, value);
    }
    const onDDLChangeValue = (value) => {
        setValue(value);
        updateMyData(index, id, value);
    }

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    if (id !== "name") {
        if (row.values.name === shownDetails.rfrRequired) {
            return <YesNo
                onChangeValue={(e) => {
                    const value = Number(e.target.value)
                    setValue(value);
                    updateMyData(index, id, value);
                }}
                value={value}
                disable={!isCreator || disable}
                radioGroupName="rfrRequired"
            />
        } else if (row.values.name === shownDetails.ermRelevant) {
            return <YesNo
                onChangeValue={(e) => {
                    const value = Number(e.target.value)
                    setValue(value);
                    updateMyData(index, id, value);
                }}
                value={value}
                disable={disable}
                radioGroupName="ermRelevant"
            />
        } else if (row.values.name === shownDetails.issueId) {
            const { Issues } = useSelector((state: RootState) => state.Issues)
            return <DDL
                disable={disable}
                onChangeValue={(selected) => onDDLChangeValue(selected.value)}
                value={value}
                options={Issues.map(i => ({ text: i.issueText, value: i.issueId }))}
            />
        } else if (row.values.name === shownDetails.status) {
            return <div className='bg-transparent ml-2'><StatusTextIcon statusId={initialValue} /></div>;

        } else if (row.values.name === shownDetails.adhocSequenceCa) {
            return <div className='bg-transparent ml-2'>{value}</div>;

        } else if (row.values.name === shownDetails.adhocUnitDevice) {
            return <div className='bg-transparent ml-2'>{value}</div>;

        } else if (row.values.name === shownDetails.disciplineId || row.values.name === shownDetails.assignedDisciplineId) {
            const disciplineName = getDisciplineName(value)
            return <div className='bg-transparent ml-2'>{disciplineName}</div>;
        } else if (row.values.name === shownDetails.materialId) {
            const { Categories } = useSelector((state: RootState) => state.Categories)
            return <DDL
                disable={disable}
                onChangeValue={(selected) => onDDLChangeValue(selected.value)}
                value={value}
                options={Categories.map(i => ({ text: i.categoryName, value: i.categoryId }))}
            />
        } else if (row.values.name === shownDetails.priority) {
            return <DDL
                disable={disable}
                onChangeValue={(selected) => onDDLChangeValue(selected.value)}
                value={value}
                options={Object.keys(NumberToPriority).map(i => ({ text: NumberToPriority[i], value: Number(i) }))}
            />
        } else if (row.values.name === shownDetails.ermType) {
            return <DDL
                disable={disable}
                onChangeValue={(selectedArray) => {
                    onDDLChangeValue(selectedArray?.map(s => s.value)?.join(','))
                }}
                value={value?.replaceAll(' ', '')?.split(',')}
                isMultiSelect={true}
                options={Object.keys(ERM).map(p => ({ text: p, value: ERM[p] }))}
            />
        } else if (row.values.name === shownDetails.dueDate) {
            const dateValue = value?.split('T')[0]
            return <DatePicker
                disable={disable}
                onChangeValue={(e) => {
                    const date = e.target.value
                    const value = new Date(date).toISOString().split('T')[0]
                    setValue(value);
                    updateMyData(index, id, value);
                }}
                value={new Date(dateValue).toISOString().split('T')[0]}
            />
        } else if (row.values.name === shownDetails.punchListId) {
            return <input className="bg-transparent ml-2" disabled={true} value={value} onChange={onChange}
                onBlur={onBlur} />;
        } else if (row.values.name === shownDetails.dueDate || row.values.name === shownDetails.dateIdentified) {
            const dateObj = new Date(value);
            const month = String(dateObj.getMonth() + 1).padStart(2, '0');
            const day = String(dateObj.getDate()).padStart(2, '0');
            const year = dateObj.getFullYear();
            const formattedDate = `${month}/${day}/${year}`;
            return <input className="bg-transparent ml-2" disabled={true} value={formattedDate} />;
        } else return disable ?
            <span style={inputStyle} className="p-2">{value}</span> :
            <input type="text" style={inputStyle} className="border-solid border-2 rounded p-2"
                value={value} onChange={onChange} onBlur={onBlur} />;
    }
    return value;
};

const YesNo = ({ onChangeValue, value, disable, radioGroupName }) => {
    return (
        <div className='flex gap-2 items-stretch'>
            <label className='rounded py-2 ml-2 text-black uppercase dark:text-white'>
                <input
                    type="radio"
                    onChange={onChangeValue}
                    checked={value === 1}
                    className="mr-2"
                    name={radioGroupName}
                    value={1}
                    disabled={disable}
                />
                Yes
            </label>
            <label className='rounded py-2 px-4 text-black uppercase dark:text-white'>
                <input
                    type="radio"
                    onChange={onChangeValue}
                    checked={value === 0}
                    className="mr-2"
                    name={radioGroupName}
                    value={0}
                    disabled={disable}
                />
                No
            </label>
        </div>
    );
};

const DDL = ({ onChangeValue, value, options = [], disable = false, isMultiSelect = false }) => {
    const selectedValue = isMultiSelect
        ? options?.filter((option) => value?.includes(option.value))
        : options?.find((option) => option.value === value) || null;

    const customSelectStyles = {
        control: (provided, state) => ({
            ...provided,
            background: disable ? 'transparent' : 'white',
            borderColor: disable ? 'transparent' : '#fdb419',
            borderWidth: disable ? 0 : 1,
            boxShadow: state.isFocused ? '0 0 0 1px #fdb419' : 'none',
            cursor: disable ? 'default' : 'pointer',
            width: '100%',
            minWidth: '200px',
            justifyContent: 'flex-start',
            whiteSpace: 'wrap',
            '@media (prefers-color-scheme: dark)': {
                background: disable ? 'transparent' : '#6e6e6e',
                borderColor: disable ? 'transparent' : '#6e6e6e',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'selectedBgColor' : '#ffffff',
            color: state.isSelected ? '#fdb419' : '#000000',
            ':hover': {
                backgroundColor: '#fdb419',
                color: '#ffffff',
            },
            '@media (prefers-color-scheme: dark)': {
                backgroundColor: state.isSelected ? 'darkSelectedBgColor' : '#6e6e6e',
                color: state.isSelected ? '#fdb419' : '#ffffff',
                ':hover': {
                    backgroundColor: '#fdb419',
                    color: '#ffffff',
                },
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'inherit',
            textAlign: 'left',
            width: '100%',
            minWidth: '200px',
            whiteSpace: 'wrap',
        }),
        dropdownIndicator: (base) => ({
            ...base,
            color: '#fdb419',
            display: disable ? 'none' : 'flex',
            '@media (prefers-color-scheme: dark)': {
                color: '#fdb419',
            },
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
    };

    return <div>
        <Select
            isDisabled={disable}
            styles={customSelectStyles}
            isMulti={isMultiSelect}
            value={selectedValue}
            onChange={onChangeValue}
            options={options}
            getOptionLabel={(option) => option.text}
        />
    </div>
}

const DatePicker = ({ onChangeValue, value, disable = false }) => {
    const datePickerStyle = disable
        ? { color: 'inherit', cursor: 'default' }
        : { cursor: 'pointer', border: '1px solid #fdb419', width: '200px' };

    return (
        <div>
            <input
                style={datePickerStyle}
                className={disable ? "bg-transparent ml-2" : "bg-transparent"}
                onChange={onChangeValue}
                value={value}
                disabled={disable}
                type="date"
            />
        </div>
    );
};

const fetchChangeLogs = async (item: PunchlistApiType, sucssCallback: (logs) => void) => {
    if (navigator.onLine) {
        try {
            const endpoint = URL.getUpdatedChangeLogsUrl(item.projectNumber, item.disciplineId, null, item.punchListId);
            const logs = await fetchApi(endpoint)
                .then(res => res.json());
            if (logs.status != 400) sucssCallback(logs)
        } catch (error) {
            console.error('Failed to fetch changelogs:', error);
        }
    }
};