import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { URL } from "../../Constants/Consts";
import { fetchApi } from "../../Helpers/Functions";
import { addProjectPunchLists } from "../../store/PunchList";
import { updateDownloadTriggered, updateHasDownloadedValue } from "../../store/Utilities";
import { RootState } from "../../store/store";
import { PunchlistApiType } from "../../types/PunchList/PunchListSchema";

export const photosKeys = {
  // order of these items really matters :)
  correctedPic1: "storedCorrectedPic1",
  correctedPic2: "storedCorrectedPic2",
  defectPic1: "storedDefectPic1",
  defectPic2: "storedDefectPic2",
}
export default function storePunchList(once: boolean = false, start: boolean = true) {
  // load all punchlists
  const [isloading, setIsloading] = useState(false)
  const dispatch = useDispatch()
  const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser)
  const [punchlists, setPunchlists] = useState([])
  const arrKeys = Object.keys(photosKeys)
  const { hasDownloaded: { punchList: downloaded }, downloadTriggered: { punchList: triggered } } = useSelector(
    (state: RootState) => state.Utilities
  );
  useEffect(() => {
    const { abort, signal } = new AbortController()
    if (!start) return
    (async () => {
      if (navigator.onLine && SelectedUser.projectNumber && SelectedUser.jwt && (!once || !downloaded || triggered)) {
        setIsloading(true)
        const storedPunchlists = await fetchApi(
          URL.getPunchListsPerProjectUrl(SelectedUser.projectNumber), { signal: signal })
          .then(res => res.json()).then(async (data: PunchlistApiType[]) => {
            // fetch all photos 
            const photosUrls = data.reduce<{ grouped: Array<string>, couter: string[] }[]>((acc, pl) => {
              let couter = []
              let grouped = []
              if (pl[arrKeys[0]]) {
                couter.push(photosKeys[arrKeys[0]])
                grouped.push(pl[arrKeys[0]])
              }
              if (pl[arrKeys[1]]) {
                couter.push(photosKeys[arrKeys[1]])
                grouped.push(pl[arrKeys[1]])
              }
              if (pl[arrKeys[2]]) {
                couter.push(photosKeys[arrKeys[2]])
                grouped.push(pl[arrKeys[2]])
              }
              if (pl[arrKeys[3]]) {
                couter.push(photosKeys[arrKeys[3]])
                grouped.push(pl[arrKeys[3]])
              }
              acc.push({ grouped, couter })
              return acc
            }, [])
            const fetchedPhotoGroups = await Promise.allSettled(photosUrls.reduce((acc, f) => {
              if (f.couter.length) acc.push(f.grouped)
              return acc.flat()
            }, []).map(p => {
              const pUrl = p.replace('punch_list_images/', '');
              return fetchApi(URL.getFilePunchListImage(encodeURIComponent(pUrl)), { signal: signal })
                .then(response => response.arrayBuffer().then(buffer => ({
                  status: 'fulfilled',
                  value: { buffer, response }
                })))
                .catch(reason => ({
                  status: 'rejected',
                  reason
                }));
            }))
            let shift = 0, amount = 0
            const newData = data.map(async (plData, i) => {
              const urlCount = photosUrls[i].couter.length
              let photoGroup = []
              if (urlCount) {
                amount += urlCount
                photoGroup = fetchedPhotoGroups.slice(shift, amount)
                shift = amount
              }
              for (let k = 0; k < photoGroup.length; k++) {
                const photoPromise = photoGroup[k]
                if (photoPromise.status === "fulfilled" && 'value' in photoPromise.value) {
                  const byteArray = await photoPromise.value.value.buffer
                  const base64String = btoa(
                    new Uint8Array(byteArray)
                      .reduce((data, byte) => data + String.fromCharCode(byte), '')
                  );
                  const str = `data:image/jpeg;base64,${base64String}`;
                  // const storedKey = photosKeys[arrKeys[k]]
                  const storedKey = photosUrls[i].couter[k]
                  plData[storedKey] = { value: str }
                }
              }
              return plData
            })
            const result = await Promise.all(newData)
            return result
          })

        setPunchlists(storedPunchlists)
        // update store
        dispatch(addProjectPunchLists(storedPunchlists))
        dispatch(updateHasDownloadedValue({ key: "punchList", value: true }))
        dispatch(updateDownloadTriggered({ key: "punchList", value: false }))
        setIsloading(false)
      }
    })()
    return () => {
      abort()
    }
  }, [SelectedUser, triggered, start])

  return { isloading, punchlists }
}
