import { RiArrowRightSLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/CoreComponents/Breadcrumb";
import List from "../../components/CoreComponents/List";
import { RootState } from "../../store/store";

export default function Sequence() {
  const navigate = useNavigate();
  const { SequencesObject } = useSelector((state: RootState) => state.SequenceObject);
  const UserAssignment = useSelector((state: RootState) => state.SelectedUser.SelectedUser);
  const breadcrumbTrail = [
    { label: UserAssignment.projectNumber },
    { label: ' - ' },
    { label: UserAssignment.project },
    { label: <RiArrowRightSLine />, icon: true },
    { label: UserAssignment.discipline },
    { label: <RiArrowRightSLine />, icon: true },
    { label: "Sequences" },
  ];

  return (
    <div className="max-w-4xl">
      <Breadcrumb trail={breadcrumbTrail} showIndices={[2, 3, 4, 5, 6]} />
      <div className="ml-2">
        <List<string>
          data={Object.keys(SequencesObject)}
          renderChildItem={(item) => (props: {}) =>
            <div {...props}>{item}</div>}
          onClick={(seq) => {
            navigate(`/Sequence/${seq}/Unit`);
          }}
          isItemDisabled={false}
        />
      </div>
    </div>
  );
}
