import { useMutation } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Status, URL } from '../../Constants/Consts'
import { setSelectedSequenceNumber, setSelectedUnitNumber, setSelectedInspectionTask, updateCheckListItem } from '../../store/SequenceObject'
import { RootState } from '../../store/store'
import { UnitChecklistItem } from '../../types/types'
import { fetchApi } from '../Functions'

export default function useSCheckList(
    { SequenceId, UnitId }
        : { SequenceId: string, UnitId: string }
) {
    if (!SequenceId || !UnitId) return {}
    const navigate = useNavigate()
    const dispatch = useDispatch()
    if (!SequenceId) return
    const { SequencesObject: Sequences } = useSelector((state: RootState) => state.SequenceObject)
    const checkListObject = Sequences[SequenceId]?.[UnitId]

    const updateItemStatus = (item: UnitChecklistItem) => {
        return fetchApi(URL.updateUnitCheckListUrl(item.checklistUuid), {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ status: item.status })
        })
    }
    const { mutate, isPending: isMutating, variables, mutateAsync } = useMutation({
        mutationFn: updateItemStatus,
        onSuccess: async (_, variables) => {
            dispatch(updateCheckListItem({ SequenceId, UnitId, checklistItem: variables }))
        },
        onError: (_, variables) => {
            if (!navigator.onLine) {
                dispatch(updateCheckListItem({ SequenceId, UnitId, checklistItem: { ...variables, notSync: true } }))
            }
        },
        networkMode: "offlineFirst"
    })

    const markPassed = (item: UnitChecklistItem) => {
        const selectedItem = checkListObject[item.checklistUuid]
        if (selectedItem) return mutateAsync({ ...selectedItem, status: Status.Passed })
    }

    const markFailed = (item: UnitChecklistItem) => {
        const selectedItem = checkListObject[item.checklistUuid]
        if (selectedItem) return mutateAsync({ ...selectedItem, status: Status.Failed })
    }

    const markNotApplicable = (item: UnitChecklistItem) => {
        const selectedItem = checkListObject[item.checklistUuid]
        if (selectedItem) mutate({ ...selectedItem, status: Status.NA })
    }

    const reset = (item: UnitChecklistItem) => {
        const selectedItem = checkListObject[item.checklistUuid]
        if (selectedItem) return mutateAsync({ ...selectedItem, status: Status.Open })
    }

    const markPending = (item: UnitChecklistItem) => {
        const selectedItem = checkListObject[item.checklistUuid]
        if (selectedItem) return mutateAsync({ ...selectedItem, status: Status.Pending })
    }

    const navigateToPunchList = (item: UnitChecklistItem) => {
        dispatch(setSelectedSequenceNumber(String(SequenceId)))
        dispatch(setSelectedUnitNumber(String(UnitId)))
        dispatch(setSelectedInspectionTask(item.inspectionTask))
        navigate(`/PunchList/Form/CheckList/${item.checklistUuid}`)
    }

    return {
        checkListObject, markPassed, markFailed, navigateToPunchList, markNotApplicable, reset, markPending,
        showLoader: { isMutating, variables }
    }
}
