import Static from 'ol/source/ImageStatic'
import { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { fextent, layerContext } from '../../components/Providers/LayersProvider'
import { RootState } from '../../store/store'
import PunchlistViewer from './PunchlistViewer'

export default function MapView() {
  const { layoutId, punchlistId } = useParams()
  const { mapObject, staticImageLayer } = useContext(layerContext)
  const mapRef = useRef<HTMLDivElement>()
  const { urls } = useSelector((state: RootState) => state.Drawings)
  const [options, setOptions] = useState([])
  useEffect(() => {
    (async () => {
      const ff = await Promise.all(urls.map(async ({ label, id, data }) => {
        const dd = await fetch(data)
          .then(response => response.blob())
        const blobUrl = window.URL.createObjectURL(dd)
        return ({ url: blobUrl, id, label })
      }))
      setOptions(ff)
    })()
  }, [urls])
  const selectedUrl = options.find(u => u.id === Number(layoutId))

  useEffect(() => {
    if (mapObject) mapObject?.setTarget(mapRef.current)
    if (staticImageLayer && selectedUrl) staticImageLayer.setSource(new Static({
      url: selectedUrl.url,
      projection: "EPSG:4326",
      imageExtent: fextent,
    }),)
  }, [mapObject, staticImageLayer, selectedUrl])

  return (
    <div style={{ width: "85vw", height: "85svh", position: "relative" }} ref={mapRef}>
      <PunchlistViewer punchlistId={punchlistId} layoutId={layoutId} />
    </div>
  )
}
