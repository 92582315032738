import { useMemo } from "react";
import { useTable } from 'react-table';
import { getDetilsFieldsArray } from "./useChecklistItemData";
import { DetailsFields } from "../../../../../Constants/Enums";
import StatusTextIcon from "../../../../../components/CoreComponents/StatusTextIcons";

export default function useCheckItemDetails({ item }) {
    const data = useMemo(() => getDetilsFieldsArray(item, DetailsFields).map(r => {
        if (r.name === 'Status') {
            r.value = <StatusTextIcon statusId={r.value} />;
        }
        return r;
    }), [item]);

    const columns = useMemo(() => [
        {
            accessor: 'name',
        },
        {
            accessor: 'value',
            Cell: ({ value }) => {
                return typeof value === 'object' ? value : <div>{value}</div>;
            }
        },
    ], []);

    return { ...useTable({ columns, data }) };
}
