import { useEffect, useState } from "react";
import { Status, imageType } from "../../../Constants/Consts.ts";
import EditSaveBtn from "../../../components/CoreComponents/Details/EditSaveBtn.tsx";
import SectionHeader from "../../../components/CoreComponents/Details/SectionHeader.tsx";
import ImageSelector from "../../../components/CoreComponents/ImageSelector.tsx";
import { PunchlistApiType } from "../../../types/PunchList/PunchListSchema.ts";

import { IoCloudDownloadOutline } from "react-icons/io5";
import useDownloadPLPhoto from "../../../Helpers/CustomHooks/useDownloadPLPhoto.tsx";
import Loader from "../../../components/CoreComponents/Loader.tsx";

export default function PunchListImages({
    item,
    imageEditEnabled,
    imageSelectorRefDefectPhoto1,
    imageSelectorRefDefectPhoto2,
    imageSelectorRefCorrectedPhoto1,
    imageSelectorRefCorrectedPhoto2,
    addEditPhotoModal
}: {
    item: PunchlistApiType,
    imageEditEnabled?: boolean,
    imageSelectorRefDefectPhoto1?: any,
    imageSelectorRefDefectPhoto2?: any,
    imageSelectorRefCorrectedPhoto1?: any,
    imageSelectorRefCorrectedPhoto2?: any,
    addEditPhotoModal
}) {

    const isStatusNotComplete = item.status !== Status.Completed;

    const [defectPhoto1, setDefectPhoto1] = useState(null);
    const [defectPhoto2, setDefectPhoto2] = useState(null);
    const [correctedPhoto1, setCorrectedPhoto1] = useState(null);
    const [correctedPhoto2, setCorrectedPhoto2] = useState(null);

    useEffect(() => {
        (async () => {
            if (item.storedDefectPic1?.value) { setDefectPhoto1(item.storedDefectPic1?.value) }
            else if (item.defectPic1) {
                const imageName = item.defectPic1.split('/')[4]
                const blob = await getBlob(imageName)
                setDefectPhoto1(blob)
            }
            if (item.storedDefectPic2?.value) setDefectPhoto2(item.storedDefectPic2?.value)
            else if (item.defectPic2) {
                const imageName = item.defectPic2.split('/')[4]
                const blob = await getBlob(imageName)
                setDefectPhoto2(blob)
            }
            if (item.storedCorrectedPic1?.value) setCorrectedPhoto1(item.storedCorrectedPic1?.value)
            else if (item.correctedPic1) {
                const imageName = item.correctedPic1.split('/')[4]
                const blob = await getBlob(imageName)
                setCorrectedPhoto1(blob)
            }
            if (item.storedCorrectedPic2?.value) setCorrectedPhoto2(item.storedCorrectedPic2?.value)
            else if (item.correctedPic2) {
                const imageName = item.correctedPic2.split('/')[4]
                const blob = await getBlob(imageName)
                setCorrectedPhoto2(blob)
            }
        })()
    }, []);

    const getBlob = async (name) => {
        try {
            const opfsRoot = await navigator.storage.getDirectory();
            const directory = await opfsRoot.getDirectoryHandle('imagesfolder')
            const fileHandle = await directory.getFileHandle(name, { create: false });
            const file = await fileHandle.getFile()
            const arrayBuffer = await file.arrayBuffer()
            return new Blob([arrayBuffer], { type: 'image/jpeg' });
        } catch (error) {
            console.error(`error happened when requesting image ${name}: `, error);
        }
    }
    console.log("-->", item);
    const { downLoadPhoto, isLoading: isPhotoDownloading } = useDownloadPLPhoto()
    const showDownloadDefect = item.defectPic1 && !defectPhoto1 || item.defectPic2 && !defectPhoto2
    const showDownloadCorrect = item.correctedPic1 && !correctedPhoto1 || item.correctedPic2 && !correctedPhoto2

    const handleDownloadDefectImages = async () => {
        const defect1 = await downLoadPhoto(item.defectPic1)
        const defect2 = await downLoadPhoto(item.defectPic2)
        if (item.defectPic1 && 'arrayBuffer' in defect1) {
            const blob = new Blob([defect1.arrayBuffer], { type: "image/jpeg" });
            setDefectPhoto1(blob)
        }
        if (item.defectPic2 && 'arrayBuffer' in defect2) {
            const blob = new Blob([defect2.arrayBuffer], { type: "image/jpeg" });
            setDefectPhoto2(blob)
        }
    }
    const handleDownloadCorrectedImages = async () => {
        const correct1 = await downLoadPhoto(item.correctedPic1)
        const correct2 = await downLoadPhoto(item.correctedPic2)
        if (item.correctedPic1 && 'arrayBuffer' in correct1) {
            const blob = new Blob([correct1.arrayBuffer], { type: "image/jpeg" });
            setCorrectedPhoto1(blob)
        }
        if (item.correctedPic2 && 'arrayBuffer' in correct2) {
            const blob = new Blob([correct2.arrayBuffer], { type: "image/jpeg" });
            setCorrectedPhoto2(blob)
        }
    }

    return (
        <>
            {isPhotoDownloading ? <Loader loaders={{ isPhotoDownloading }} /> :
                <div>
                    <div className="flex flex-wrap items-center w-full">
                        <div className="flex gap-3 items-center mr-3">
                            <div className={`flex gap-3 items-center`}>
                                <div className={'font-bold text-xl uppercase'}>
                                    <SectionHeader title="Images" />
                                </div>
                                {isStatusNotComplete && !imageEditEnabled ?
                                    <EditSaveBtn
                                        disable={!imageEditEnabled}
                                        onClickHandler={() => addEditPhotoModal()}
                                        buttonText={'Add/Edit Images'}
                                    /> : ''}
                            </div>
                        </div>
                    </div>

                    {/* DEFECT IMAGES */}
                    <div>
                        <div className="mt-4" style={!!defectPhoto1 || imageEditEnabled ? { display: '' } : { display: 'none' }}>
                            <h2 className='text-base font-bold mb-2'>Defect Photo 1</h2>
                            <div className='w-full'>
                                <ImageSelector ref={imageSelectorRefDefectPhoto1} originalImage={defectPhoto1}
                                    disabled={!imageEditEnabled} imageType={imageType}
                                    showChangedWarning={true}></ImageSelector>
                            </div>
                        </div>
                        <div className="mt-4" style={!!defectPhoto2 || imageEditEnabled ? { display: '' } : { display: 'none' }}>
                            <h2 className='text-base font-bold mb-2'>Defect Photo 2</h2>
                            <div className='w-full'>
                                <ImageSelector ref={imageSelectorRefDefectPhoto2} originalImage={defectPhoto2}
                                    disabled={!imageEditEnabled} imageType={imageType}
                                    showChangedWarning={true}></ImageSelector>
                            </div>
                        </div>

                        {/* This button should only show if an image exists, but has not been downloaded yet */}

                        {showDownloadDefect && <button
                            className="flex items-center ml-4 mt-4 px-4 py-2 bg-orange-1000 text-white rounded hover:bg-blue-600 transition-colors duration-300"
                            onClick={handleDownloadDefectImages}
                        >
                            <IoCloudDownloadOutline className="text-white" />
                            <span className="ml-2">Download defect image(s)</span>
                        </button>}

                        {/* If no images exist, just add a text saying No images have been added to this punch list */}

                    </div>

                    {/* CORRECTED IMAGES */}
                    <div>
                        <div className="mt-4" style={!!correctedPhoto1 || imageEditEnabled ? { display: '' } : { display: 'none' }}>
                            <h2 className='text-base font-bold mb-2'>Corrected Photo 1</h2>
                            <div className='w-full'>
                                <ImageSelector ref={imageSelectorRefCorrectedPhoto1} originalImage={correctedPhoto1}
                                    disabled={!imageEditEnabled} imageType={imageType}
                                    showChangedWarning={true}></ImageSelector>
                            </div>
                        </div>
                        <div className="mt-4" style={!!correctedPhoto2 || imageEditEnabled ? { display: '' } : { display: 'none' }}>
                            <h2 className='text-base font-bold mb-2'>Corrected Photo 2</h2>
                            <div className='w-full'>
                                <ImageSelector ref={imageSelectorRefCorrectedPhoto2} originalImage={correctedPhoto2}
                                    disabled={!imageEditEnabled} imageType={imageType}
                                    showChangedWarning={true}></ImageSelector>
                            </div>
                        </div>

                        {/* This button should only show if an image exists, but has not been downloaded yet */}

                        {showDownloadCorrect && <button
                            className="flex items-center ml-4 mt-4 px-4 py-2 bg-orange-1000 text-white rounded hover:bg-blue-600 transition-colors duration-300"
                            onClick={handleDownloadCorrectedImages}
                        >
                            <IoCloudDownloadOutline className="text-white" />
                            <span className="ml-2">Download corrected image(s)</span>
                        </button>}

                        {/* If no images exist, just add a text saying No images have been added to this punch list */}

                    </div>
                </div>}
        </>
    )
}
