import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CookiesKeys, URL } from '../../Constants/Consts'
import { fetchApi } from '../../Helpers/Functions'
import { addIssues } from '../../store/Issues'
import { updateDownloadTriggered, updateHasDownloadedValue } from '../../store/Utilities'
import { RootState } from '../../store/store'
import Cookies from "js-cookie";

export default function storeIssues() {
    const [isloading, setIsloading] = React.useState(false)
    const dispatch = useDispatch()
    const SelectedUser = useSelector(
        (state: RootState) => state.SelectedUser.SelectedUser
    );
    const { downloadTriggered: { issues: triggered } } = useSelector(
        (state: RootState) => state.Utilities
    );
    const calledOnceRef = useRef(false)
    const currentToken = Cookies.get(CookiesKeys.jwt)
    React.useEffect(() => {
        (async () => {
            try {
                if (navigator.onLine && SelectedUser.jwt && currentToken && (!calledOnceRef.current || triggered)) {
                    calledOnceRef.current = true
                    setIsloading(true)
                    const issues = await fetchApi(
                        URL.getIssuesUrl())
                        .then(res => res.json())
                    // update store
                    dispatch(addIssues(issues))
                    dispatch(updateHasDownloadedValue({ key: "issues", value: true }))
                    dispatch(updateDownloadTriggered({ key: "issues", value: false }))
                    setIsloading(false)
                }
            } catch (error) {
                console.error("error from download issues", error);
            }
        })()
    }, [SelectedUser.jwt, triggered])
    return { isloading }
}
