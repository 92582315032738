import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { findSyncSequences } from '../components/SyncManager/CheckListSync';
import { SequenceObject } from '../types/Sequence/Sequence';
import { MPCStatus, UnitChecklistItem } from '../types/types';

const initialState:
  {
    SequencesObject: SequenceObject,
    selectedUnitNumber: string,
    selectedSequenceNumber: string,
    selectedInspectionTask: string | null,
    MPCStatus?: Array<MPCStatus>
  } = {
  SequencesObject: {},
  selectedSequenceNumber: '',
  selectedUnitNumber: '',
  selectedInspectionTask: null,
  MPCStatus: []
}

function overridePayloadStatus(object, items: Array<UnitChecklistItem>) {
  if (items.length === 0) return
  for (let prop in object) {
    if (typeof object[prop] === 'object' && object[prop] !== null) {
      overridePayloadStatus(object[prop], items)
    } else if (prop === 'checklistUuid' && object[prop] !== null) {
      const toSyncItem = items.find(c => c.checklistUuid === object[prop])
      if (toSyncItem) {
        object.status = toSyncItem.status
        object["notSync"] = toSyncItem.notSync
      }
    }
  }
}

const SequencesSlice = createSlice({
  name: 'sequences',
  initialState,
  reducers: {
    addSequences: (state, action: PayloadAction<SequenceObject>) => {
      const notSyncChecklists = findSyncSequences(state.SequencesObject)
      // get the new items out to be added later
      const updated = notSyncChecklists.filter(f => !f.creation)
      // override the notSync items with current ones 
      overridePayloadStatus(action.payload, updated)
      // add the new notSync items to the current ones
      notSyncChecklists.forEach(notSyncElement => {
        if (notSyncElement.creation)
          action.payload[notSyncElement.sequenceNumber][notSyncElement.unitNumber][notSyncElement.checklistUuid] = notSyncElement;
      });
      // add the new payload
      state.SequencesObject = action.payload
    },
    changeCheckListItemStatus: (state, action: PayloadAction<{
      SequenceId: string, UnitId: string, checklistUuid: string, status: number, notSync?: boolean
    }>) => {
      state.SequencesObject[action.payload.SequenceId][action.payload.UnitId][action.payload.checklistUuid.toString()].status = action.payload.status;
      state.SequencesObject[action.payload.SequenceId][action.payload.UnitId][action.payload.checklistUuid.toString()].notSync = action.payload.notSync;
    },
    updateCheckListItem: (state, action: PayloadAction<{ SequenceId: string | number, UnitId: string | number, checklistItem: UnitChecklistItem }>) => {
      state.SequencesObject[action.payload.SequenceId][action.payload.UnitId][action.payload.checklistItem.checklistUuid.toString()] = action.payload.checklistItem
    },
    setSelectedSequenceNumber: (state, action: PayloadAction<string>) => {
      state.selectedSequenceNumber = action.payload ?? ""
    },
    setSelectedUnitNumber: (state, action: PayloadAction<string>) => {
      state.selectedUnitNumber = action.payload ?? ""
    },
    setSelectedInspectionTask: (state, action: PayloadAction<string>) => {
      console.log('Reducer received inspection task:', action.payload);
      state.selectedInspectionTask = action.payload;
    },
    addMPCStatus: (state, action: PayloadAction<Array<MPCStatus>>) => {
      state.MPCStatus = action.payload
    },
    updateMPCStatus: (state, action: PayloadAction<{ SequenceId: string | number, UnitId: string | number, MPCStatus: Array<MPCStatus> }>) => {
      for (let i = 0; i < action.payload.MPCStatus.length; i++) {
        const mpcStatusItem = action.payload.MPCStatus[i];
        state.SequencesObject[action.payload.SequenceId][action.payload.UnitId][mpcStatusItem.meChecklistUuid].mpcStatus = mpcStatusItem.mpcStatus
      }
    },
    addNewTask: (
      state,
      action: PayloadAction<{ SequenceId: string | number; UnitId: string | number; newTask: UnitChecklistItem }>
    ) => {
      const { SequenceId, UnitId, newTask } = action.payload;
      const sequenceIdNumber = SequenceId;
      const unitIdNumber = UnitId;
      const checklistUuidString = newTask.checklistUuid?.toString() ?? crypto.randomUUID().toUpperCase();
      if (!state.SequencesObject[sequenceIdNumber]) {
        state.SequencesObject[sequenceIdNumber] = {};
      }
      if (!state.SequencesObject[sequenceIdNumber][unitIdNumber]) {
        state.SequencesObject[sequenceIdNumber][unitIdNumber] = {};
      }
      state.SequencesObject[sequenceIdNumber][unitIdNumber][checklistUuidString] = newTask;
    },
    updateTaskSyncStatus: (
      state,
      action: PayloadAction<{
        SequenceId: string;
        UnitId: string;
        checklistUuid: string;
        notSync: boolean;
      }>
    ) => {
      const { SequenceId, UnitId, checklistUuid, notSync } = action.payload;
      const checklistItem = state.SequencesObject[SequenceId][UnitId][checklistUuid];

      if (checklistItem) {
        checklistItem.notSync = notSync;
      } else {
        console.error('Checklist item not found for sync update.');
      }
    },
  },
});

export const {
  addSequences,
  changeCheckListItemStatus,
  updateCheckListItem,
  setSelectedSequenceNumber,
  setSelectedUnitNumber,
  setSelectedInspectionTask,
  addMPCStatus,
  updateMPCStatus,
  addNewTask } = SequencesSlice.actions;
export default SequencesSlice.reducer;