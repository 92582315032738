import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { CookiesKeys, URL, imageType } from "../../Constants/Consts";
import { updatePunchlistItem } from "../../store/PunchList";
import { ObjectToSync } from "../../types/types";
import { UUID } from "crypto";
import { SyncItemsTypes } from "./SyncManager";
import { photosKeys } from "../DownloadManager/storePunchList";
import Cookies from "js-cookie";
import { PhotoType } from "../../Pages/PunchList/DetailsPage/PunchListEditImagesPage";
import { mapPunchListToAPI } from "../../Helpers/Mappers";

// track punchlists
export default function (): { getPunchlistsToSync: () => Promise<ObjectToSync>[], updateSyncItem: (itemId: UUID) => void } {
    const dispatch = useDispatch()
    const { ProjectPunchLists } = useSelector((state: RootState) => state.PunchList)
    if (!Array.isArray(ProjectPunchLists)) return { getPunchlistsToSync: () => [], updateSyncItem: () => { } }
    const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser)

    const getPunchlistsToSync = () => {
        // get all the notSync items 
        const filteredLists = ProjectPunchLists.filter(p => {
            const isPhotosNotSyncArray = Object.keys(photosKeys).map(k => p[photosKeys[k]]?.notSync)
            return p.notSync || isPhotosNotSyncArray.some(ns => ns)
        })
        const jwt = Cookies.get(CookiesKeys.jwt)
        return filteredLists.map(async (pl) => {
            const preRequests = []
            const keysArray = Object.keys(photosKeys)
            for (let i = 0; i < keysArray.length; i++) {
                const photoKey = keysArray[i];
                const photoLabel = photosKeys[photoKey] as string
                if (pl[photoLabel]?.notSync) {
                    const photoType = photoLabel.toLocaleLowerCase().includes("def") ? PhotoType.DEFECT : PhotoType.CORRECTED
                    const photoNumber = i % 2 + 1
                    const url = URL.postFilePunchListImage(SelectedUser.projectNumber, pl.punchListId, photoType)
                    const method = !pl[photoLabel]?.creation ? "PUT" : "POST"
                    const photoName = `${pl.punchListId}.${photoType.toLowerCase()}_pic_${photoNumber}.${imageType}`;
                    const it = {
                        url,
                        method,
                        payload: { value: pl[photoLabel]?.value, photoName, photoType, label: `${photoType.toLowerCase()}Pic${photoNumber}` },
                        type: SyncItemsTypes.PunchlistItem
                    }
                    preRequests.push(it)
                }
            }
            const url = !pl.creation ? URL.putPunchListsUrl(pl.punchListId) : URL.postPunchListsUrl()
            const method = !pl.creation ? "PUT" : "POST"
            const mappedPl = mapPunchListToAPI(pl)
            return {
                url,
                method,
                payload: mappedPl,
                type: SyncItemsTypes.PunchlistItem,
                preRequests,
                jwt
            }
        })
    }

    const updateSyncItem = (punchlistId) => {
        const toUpdateIndex = ProjectPunchLists.findIndex(pl => pl.punchListId === punchlistId)
        if (toUpdateIndex >= 0) {
            const toUpdate = structuredClone(ProjectPunchLists[toUpdateIndex])
            const keysArray = Object.keys(photosKeys)
            for (let i = 0; i < keysArray.length; i++) {
                const storedPhotoKey = photosKeys[keysArray[i]]
                toUpdate[storedPhotoKey] = { ...toUpdate[storedPhotoKey], notSync: false }
            }
            dispatch(updatePunchlistItem({ ...toUpdate, notSync: false, creation: false }))
        }
    }
    return { getPunchlistsToSync, updateSyncItem }
}

