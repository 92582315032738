import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PunchListActionButtons from '../../../../src/components/ActionButtons/PunchListActionButtons';
import Comments from '../../../components/CoreComponents/Comments/Comments.tsx';
import DetailGrid from '../../../components/CoreComponents/Details/DetailsGrid.tsx';
import SectionHeader from "../../../components/CoreComponents/Details/SectionHeader.tsx";
import { RootState } from '../../../store/store';
import PunchListDetails from "./PunchListDetails.tsx";
import PunchListImages from "./PunchListImages.tsx";
import Changelogs from '../../../components/CoreComponents/Changelog/Changelogs.tsx';
import { setModal } from "../../../store/Utilities.ts";

export default function DetailsPage() {
    const { punchListId } = useParams();
    const filteredPunchList = useSelector((state: RootState) => { return state.PunchList.ProjectPunchLists.find((item) => item.punchListId === punchListId) });
    const relatedChecklistDetails = useSelector((state: RootState) => state.RelatedChecklistDetails.relatedChecklistDetails);
    const relatedLoadedChecklistDetails = useSelector((state: RootState) => state.SequenceObject.SequencesObject);
    const getChecklistDetails = useCallback((checklistUuid) => {
    
        if (checklistUuid) {
            for (const sequence in relatedLoadedChecklistDetails) {
                const sequenceObj = relatedLoadedChecklistDetails[sequence];
                for (const unit in sequenceObj) {
                    if (sequenceObj[unit][checklistUuid]) {
                        const detail = sequenceObj[unit][checklistUuid];
                        return {
                            sequenceNumber: sequence,
                            unitNumber: unit,
                            modelFull: detail.modelFull || 'N/A',
                            inspectionTask: detail.inspectionTask || 'N/A',
                        };
                    }
                }
            }
    
            const detail = relatedChecklistDetails.find(d => d.checklistUuid === checklistUuid);
            
            if (detail) {
                return {
                    sequenceNumber: detail.sequenceNumber,
                    unitNumber: detail.unitNumber,
                    modelFull: detail.modelFull || 'N/A',
                    inspectionTask: detail.inspectionTask || 'N/A',
                };
            }
    
        } else if (filteredPunchList?.adhocSequenceCa) {
            return {
                sequenceNumber: filteredPunchList?.adhocSequenceCa,
                unitNumber: filteredPunchList?.adhocUnitDevice,
                modelFull: 'N/A',
                inspectionTask: 'N/A',
            };
        }
    
        return {
            sequenceNumber: 'N/A',
            unitNumber: 'N/A',
            modelFull: 'N/A',
            inspectionTask: 'N/A',
        };
    }, [relatedLoadedChecklistDetails, relatedChecklistDetails, filteredPunchList?.adhocSequenceCa, filteredPunchList?.adhocUnitDevice]);

    const checklistUuid = filteredPunchList?.checklistUuid;
    const checklistDetailsObject = getChecklistDetails(checklistUuid);
    const checklistDetails = useMemo(() => [
        { label: "Model", value: checklistDetailsObject.modelFull?.toString() },
        { label: "Inspection Task", value: checklistDetailsObject.inspectionTask?.toString() }
    ], [checklistDetailsObject]);

    const generalDetails = useMemo(() => {
        const details = [
            { label: "Checklist UUID", value: filteredPunchList?.checklistUuid },
            { label: "Punch List UUID", value: filteredPunchList?.punchListId },
            { label: "Created By Email", value: filteredPunchList?.identifiedBy },
        ];
        return details.filter(detail => detail.value != null);
    }, [filteredPunchList]);
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const addEditPhotoModal = () => {
        if (detailsEditEnabled) {
            navigate(`/PunchList/DetailsPage/${filteredPunchList?.punchListId}/Image`);
        }
        dispatch(setModal({
            Chilren: () =>
                <div>
                    Proceeding will discard any changes that haven't been saved. Do you wish to continue?<br />
                </div>,
            onConfirm: () => {
                navigate(`/PunchList/DetailsPage/${filteredPunchList?.punchListId}/Image`)
            },
            open: true
        }));
    }

    const [detailsEditEnabled, setDetailsEditEnabled] = useState(false);

    return (
        <>
            <PunchListActionButtons item={filteredPunchList} addEditPhotoModal={addEditPhotoModal} />

            <div className="max-w-4xl">

                <PunchListDetails
                    item={filteredPunchList}
                    detailsEditEnabled={detailsEditEnabled}
                    setDetailsEditEnabled={setDetailsEditEnabled}
                />

                <hr className="border-t mt-8 mb-8" />

                <PunchListImages item={filteredPunchList} addEditPhotoModal={addEditPhotoModal} />

                <hr className="border-t mt-8 mb-8" />

                <div className="flex flex-col mb-4">
                    <SectionHeader title="Checklist Details" />
                </div>

                <DetailGrid details={checklistDetails} />

                <hr className="border-t mt-8 mb-8" />

                <div className="flex flex-col mt-16 mb-4">
                    <Comments item={filteredPunchList} />
                </div>

                <div className="flex flex-col mt-16 mb-4">
                    <Changelogs item={filteredPunchList} />
                </div>

                <div className="flex flex-col mt-16 mb-4">
                    <SectionHeader title="General Details" />
                </div>

                <DetailGrid details={generalDetails} />

            </div>
        </>
    );
}

